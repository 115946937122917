import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import LoginView from 'components/login/LoginView';

const useStyles = makeStyles(theme => ({
  '@global': {
    a: {
      color: 'rgba(0, 0, 0, 0.87)',
      display: 'inherit',
      textDecoration: 'none',
    },
  },
}));

export default function LoginGuard({children}) {
  useStyles();

  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  if (!isLoggedIn) {
    return <LoginView />;
  }
  return children;
}

LoginGuard.propTypes = {
  children: PropTypes.node,
};
