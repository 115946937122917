export default [
  ({
    selectors: [],
    selectorsAdd: '#outlook a',
    style: {
      'padding-top': '0px',
      'padding-right': '0px',
      'padding-bottom': '0px',
      'padding-left': '0px',
    },
  },
  {
    selectors: [],
    selectorsAdd: 'body',
    style: {
      'margin-top': '0px',
      'margin-right': '0px',
      'margin-bottom': '0px',
      'margin-left': '0px',
      'padding-top': '0px',
      'padding-right': '0px',
      'padding-bottom': '0px',
      'padding-left': '0px',
    },
  },
  {
    selectors: [],
    selectorsAdd: 'table, td',
    style: {'border-collapse': 'collapse'},
  },
  {
    selectors: [],
    selectorsAdd: 'img',
    style: {
      'border-top-color': 'currentcolor',
      'border-top-style': 'none',
      'border-top-width': '0px',
      'border-right-color': 'currentcolor',
      'border-right-style': 'none',
      'border-right-width': '0px',
      'border-bottom-color': 'currentcolor',
      'border-bottom-style': 'none',
      'border-bottom-width': '0px',
      'border-left-color': 'currentcolor',
      'border-left-style': 'none',
      'border-left-width': '0px',
      'border-image-outset': '0',
      'border-image-repeat': 'stretch',
      'border-image-slice': '100%',
      'border-image-source': 'none',
      'border-image-width': '1',
      height: 'auto',
      'line-height': '100%',
      'outline-color': 'currentcolor',
      'outline-style': 'none',
      'outline-width': 'medium',
      'text-decoration-line': 'none',
      'text-decoration-style': 'solid',
      'text-decoration-color': 'currentcolor',
      'text-decoration-thickness': 'auto',
    },
  },
  {
    selectors: [],
    selectorsAdd: 'p',
    style: {
      display: 'block',
      'margin-top': '13px',
      'margin-right': '0px',
      'margin-bottom': '13px',
      'margin-left': '0px',
    },
  },
  {
    selectors: [
      {
        name: 'mj-column-per-100',
        label: 'mj-column-per-100',
        type: 1,
        active: true,
        private: false,
        protected: false,
      },
    ],
    style: {width: '100% !important', 'max-width': '100%'},
    mediaText: 'only screen and (min-width: 480px)',
    atRuleType: 'media',
  },
  {
    selectors: [
      {
        name: 'hidden',
        label: 'hidden',
        type: 1,
        active: true,
        private: false,
        protected: false,
      },
    ],
    style: {
      display: 'none',
    },
  }),
];
