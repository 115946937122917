import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import Fields from '@material-ui/icons/Dns';
import Edit from '@material-ui/icons/Edit';
import Duplicate from '@material-ui/icons/FileCopy';
import DotsIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import Delete from '@material-ui/icons/Delete';
import Mail from '@material-ui/icons/Mail';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from '@reach/router';
import dayjs from 'dayjs';

import {api} from 'services';
import Table from 'components/misc/Table';

const Channels = ({projectId}) => {
  const dispatch = useDispatch();
  const channels = useSelector(
    state =>
      Object.values(state.channels.items).filter(
        c => c.projectId === projectId
      ),
    isEqual
  );
  const campaigns = useSelector(state => state.campaigns.items);
  const groups = useSelector(state => state.groups.items);
  const isLoading = useSelector(state => state.channels.isLoading);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRow, setMenuRow] = useState(null);

  if (isLoading) return null;

  const campaignsByChannel = {};
  for (let camp of Object.values(campaigns)) {
    campaignsByChannel[camp.channelId] = camp;
  }

  function handleClick(event, row) {
    setAnchorEl(event.currentTarget.parentElement);
    setMenuRow(row);
  }

  function handleClose(cb) {
    return function () {
      setAnchorEl(null);
      setMenuRow(null);
      if (cb) {
        cb();
      }
    };
  }

  return (
    <>
      {menuRow && menuRow.id ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose()}
        >
          <MenuItem>
            <Link to={`${menuRow.id}/edit`}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Editar Formulário" />
            </Link>
          </MenuItem>

          <MenuItem>
            <Link to={`../groups/${menuRow.groupId}`}>
              <ListItemIcon>
                <Fields />
              </ListItemIcon>
              <ListItemText primary="Editar Campos" />
            </Link>
          </MenuItem>

          <MenuItem>
            <Link to={`${menuRow.id}/pipeline`}>
              <ListItemIcon>
                <Mail />
              </ListItemIcon>
              <ListItemText primary="Automação" />
            </Link>
          </MenuItem>

          <MenuItem
            onClick={handleClose(async () => {
              await api.duplicateChannel({id: menuRow.id});
              dispatch({type: 'channelsOutdated'});
            })}
          >
            <ListItemIcon>
              <Duplicate />
            </ListItemIcon>
            <ListItemText primary="Duplicar Formulário" />
          </MenuItem>

          <MenuItem
            onClick={handleClose(async () => {
              if (window.confirm(`Deletar formulário ${menuRow.name}?`)) {
                await api.deleteChannel(menuRow.id);
                dispatch({type: 'channelsOutdated'});
              }
            })}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Deletar Formulário" />
          </MenuItem>
        </Menu>
      ) : null}
      <Table
        isLoading={isLoading}
        title="Formulários"
        columns={[
          {title: 'Nome', field: 'name'},
          {title: 'Nome_', field: 'name_', hidden: true, searchable: true},
          {title: 'Campos', field: 'fieldsCount'},
          {
            title: 'Data',
            field: 'date',
            defaultSort: 'desc',
            customSort: (a, b) => a.createdAt - b.createdAt,
          },
        ]}
        data={channels.map(c => {
          const g = groups[c.group] ? groups[c.group] : {};
          return {
            name: c.name,
            name_: c.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            groupId: g.id,
            fieldsCount: g.fields ? Object.keys(g.fields).length : '',
            date: c.createdAt.split('T')[0],
            createdAt: dayjs(c.createdAt),
            id: c.id,
          };
        })}
        rowLinkTo={row => `${row.id}`}
        actions={[
          {
            icon: () => <DotsIcon />,
            tooltip: 'Ações',
            onClick: handleClick,
          },
        ]}
      />
    </>
  );
};

Channels.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Channels;
