import React, {forwardRef} from 'react';
import {merge} from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {MTableAction, MTablePagination} from 'material-table';
import {Link} from '@reach/router';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4),
  },
  cellLink: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
}));

const Table = ({rowLinkTo, ...props}) => {
  const classes = useStyles();

  if (rowLinkTo) {
    // Make row clickable using anchor.
    for (let col of props.columns) {
      col.innerRender = col.render;
      col.render = row => (
        <Link className={classes.cellLink} to={rowLinkTo(row)}>
          {col.innerRender ? col.innerRender(row) : row[col.field]}
        </Link>
      );
    }

    // Fix row spacing.
    props.options = merge(props.options, {
      cellStyle: {
        padding: '0',
      },
    });

    // Make row style change on hover.
    if (!props.onRowClick) {
      props.onRowClick = () => {};
    }
  }

  return (
    <MaterialTable
      icons={tableIcons}
      {...props}
      options={{
        emptyRowsWhenPaging: false,
        pageSize: 100,
        pageSizeOptions: [10, 20, 50, 100],
        actionsColumnIndex: -1,
        debounceInterval: 500,
        actionsCellStyle: {width: '1%'},
        ...props.options,
      }}
      components={{
        Pagination: MTablePagination,
        Action: props =>
          props.action.href ? (
            <Link to={props.action.href(props.data)}>
              <MTableAction
                action={{...props.action, onClick: false}}
                data={props.data}
              />
            </Link>
          ) : (
            <MTableAction action={props.action} data={props.data} />
          ),
      }}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  title: PropTypes.string.isRequired,
  editable: PropTypes.object,
  actions: PropTypes.array,
  isLoading: PropTypes.bool,
  options: PropTypes.object,
};

export default Table;
