import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {CheckboxWithLabel, TextField, Select} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';
import {cloneDeep} from 'lodash';
import Title from 'components/misc/Title';
import {api, history} from 'services';

const schema = yup.object().shape({
  name: yup.string().required(),
  label: yup.string().required(),
  type: yup.string().required(),
  validation: yup.string(),
  public: yup.boolean(),
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
}));

const FieldForm = ({groupId, fieldId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const fieldTypes =
    useSelector(state => state.constants.items.fieldTypes) || [];
  const fieldValidations =
    useSelector(state => state.constants.items.fieldValidations) || [];
  const field = useSelector(state => state.fields.items[fieldId]); // Used when is a field edit form
  const isLoading = useSelector(state =>
    Boolean((fieldId && !field) || state.constants.isLoading)
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  let title = 'Novo Campo';
  let submitLabel = 'Criar';
  let submitFn = api.createField;
  let postSubmitFn = ({resetForm}) => resetForm();
  let initialValues = {
    name: '',
    label: '',
    type: '',
    validation: [],
    public: false,
  };
  if (field) {
    title = 'Editar Campo';
    submitLabel = 'Salvar';
    submitFn = async c =>
      await api.editField(Object.assign(c, {fieldId: field.id}));
    postSubmitFn = () => history.navigate(`..`);
    initialValues = cloneDeep(field);
  }
  return (
    <>
      <Title>{title}</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          await submitFn({
            groupId,
            name: values.name,
            label: values.label,
            type: values.type,
            validation: values.validation,
            public: values.public,
            order: values.order,
          });
          dispatch({type: 'channelsOutdated'});
          setSubmitting(false);
          postSubmitFn({resetForm});
        }}
      >
        {({isSubmitting}) => (
          <Form className={classes.root}>
            <Field
              className={classes.field}
              name="name"
              component={TextField}
              label="Nome"
            />

            <Field
              className={classes.field}
              name="label"
              component={TextField}
              label="Rótulo"
            />

            <Field
              className={classes.field}
              name="type"
              component={TextField}
              label="Tipo"
              select
            >
              {fieldTypes.map(type => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Field>

            <InputLabel htmlFor="validation">Validações</InputLabel>
            <Field name="validation" component={Select} multiple={true}>
              {fieldValidations.map(type => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Field>

            <Field
              name="public"
              Label={{label: 'Público'}}
              component={CheckboxWithLabel}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {submitLabel}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

FieldForm.propTypes = {
  groupId: PropTypes.string.isRequired,
  fieldId: PropTypes.string,
};

export default FieldForm;
