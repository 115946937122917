import React from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Title from 'components/misc/Title';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import Delete from '@material-ui/icons/Delete';

import useMainStyles from 'components/styles';

const cmpTypes = [
  {label: 'igual a', val: 'eq'},
  {label: 'contém', val: 'contains'},
  {label: 'começa com', val: 'startsWith'},
  {label: 'termina com', val: 'endsWith'},
  {label: 'maior que', val: 'gt'},
];

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginRight: theme.spacing(1),
  },
  inputInner: {
    padding: '4px 6px',
  },
  selectInner: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  filterContent: {
    marginLeft: theme.spacing(2),
  },
  filterBox: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderLeft: '1px solid #ccc',
    borderRadius: '5px',
    transition: 'all 0.5s',
    '&:hover': {
      borderLeft: '1px solid black',
      borderRadius: '10px',
    },
  },
}));

const Event = ({event, onChange}) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        placeholder="Campo"
        value={event.key}
        onChange={e => {
          event.key = e.target.value;
          onChange();
        }}
        className={classes.input}
        InputProps={{
          classes: {input: classes.inputInner},
        }}
      />

      <Select
        value={event.cmp}
        variant="outlined"
        onChange={e => {
          event.cmp = e.target.value;
          onChange();
        }}
        className={classes.input}
        classes={{root: classes.selectInner}}
      >
        {cmpTypes.map((t, i) => (
          <MenuItem key={i} value={t.val}>
            {t.label}
          </MenuItem>
        ))}
      </Select>

      <TextField
        placeholder="Valor"
        value={event.value}
        onChange={e => {
          event.value = e.target.value;
          onChange();
        }}
        className={classes.input}
        InputProps={{
          classes: {input: classes.inputInner},
        }}
      />
    </div>
  );
};

const SelectChan = ({projectId, obj, onChange}) => {
  const classes = useStyles();
  const channels = useSelector(
    state =>
      Object.values(state.channels.items).filter(
        c => c.projectId === projectId
      ),
    isEqual
  );
  return (
    <Select
      value={obj.value || 'none'}
      variant="outlined"
      onChange={e => {
        obj.value = e.target.value;
        onChange();
      }}
      className={classes.input}
      classes={{root: classes.selectInner}}
    >
      <MenuItem key="none" value="none">
        Escolha um formulário
      </MenuItem>
      {channels.map((t, i) => (
        <MenuItem key={i} value={t.id}>
          {t.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const Filter = ({projectId, filter, onDelete, onChange, cmpVal}) => {
  const classes = useStyles();

  const filterTypes = [
    {
      label: 'Se inscreveu em formulário',
      val: cmpVal,
      init: {key: 'channels', cmp: 'contains', value: ''},
    },
    {label: 'Comparação', val: cmpVal, init: {key: '', cmp: 'eq', value: ''}},

    {label: 'Se todas verdadeiras', val: 'and', init: [{}, {}]},
    {label: 'Se qualquer verdadeira', val: 'or', init: [{}, {}]},
    {label: 'Se falso', val: 'not', init: {}},
  ];

  let type = null;
  for (let t of filterTypes) {
    if (filter[t.val]) {
      type = t;
      break;
    }
  }
  // Special rule to differ channel comparison from generic comparison.
  const chanCmp = filterTypes[0];
  const genericCmp = filterTypes[1];
  if (
    type === chanCmp &&
    (filter[type.val].key !== chanCmp.init.key ||
      filter[type.val].cmp !== chanCmp.init.cmp)
  ) {
    type = genericCmp;
  }

  if (!type) {
    const t = chanCmp;
    type = t;
    filter[type.val] = JSON.parse(JSON.stringify(t.init));
  }

  const clauses = filter.and || filter.or;

  return (
    <div className={classes.filterBox}>
      <Box display="flex">
        {onDelete ? (
          <IconButton title="remover" size="small" onClick={onDelete}>
            <Delete />
          </IconButton>
        ) : null}
        <Select
          className={classes.input + ' ' + classes.marginBottom}
          classes={{root: classes.selectInner}}
          value={type.label}
          variant="outlined"
          onChange={e => {
            delete filter[type.val];
            let newType = e.target.value;
            for (let t of filterTypes) {
              if (t.label === newType) {
                filter[t.val] = JSON.parse(JSON.stringify(t.init));
                onChange();
                break;
              }
            }
          }}
        >
          {filterTypes.map((t, i) => (
            <MenuItem key={i} value={t.label}>
              {t.label}
            </MenuItem>
          ))}
        </Select>
        {clauses ? (
          <IconButton
            title="adicionar"
            size="small"
            onClick={() => {
              clauses.push({});
              onChange();
            }}
          >
            <AddBox />
          </IconButton>
        ) : null}
      </Box>

      <div className={classes.filterContent}>
        {type === chanCmp ? (
          <SelectChan
            projectId={projectId}
            obj={filter[cmpVal]}
            onChange={onChange}
          />
        ) : type === genericCmp ? (
          <Event event={filter[cmpVal]} onChange={onChange} />
        ) : filter.not ? (
          <Filter
            cmpVal={cmpVal}
            projectId={projectId}
            filter={filter.not}
            onChange={onChange}
          />
        ) : (
          <>
            {clauses.map((f, i) => (
              <Filter
                key={i}
                cmpVal={cmpVal}
                projectId={projectId}
                onChange={onChange}
                filter={f}
                onDelete={() => {
                  clauses.splice(i, 1);
                  onChange();
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const CondEditor = ({projectId, condition, onChange, cmpVal, frame = true}) => {
  const classes = useMainStyles();

  const filter = (
    <Filter
      projectId={projectId}
      filter={condition.filter}
      cmpVal={cmpVal}
      onChange={() => onChange(condition)}
    />
  );

  if (frame) {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>Condição</Title>
          {filter}
        </Paper>
      </Grid>
    );
  } else {
    return filter;
  }
};

CondEditor.propTypes = {
  condition: PropTypes.object,
  cmpVal: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CondEditor;
