import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAdd from '@material-ui/icons/PersonAdd';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Gravatar from 'react-gravatar';
import {TextField} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';
import {cloneDeep} from 'lodash';

import Title from 'components/misc/Title';
import Layout from 'components/misc/Layout';
import {api, history} from 'services';

const schema = yup.object().shape({
  name: yup.string().required(),
  mailService: yup.string().required(),
  domains: yup.string().required(),
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  users: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const mailServices = [
  {
    label: 'nenhum',
    key: 'none',
    display: {},
    encode: () => '',
    decode(str) {
      return str === '' ? {mailService: this.key} : false;
    },
  },
  {
    label: 'MailChimp',
    key: 'mailchimp',
    display: {apiKey: true, listId: true},
    encode(values) {
      return `${this.key}:${values.apiKey}:${values.listId}`;
    },
    decode(str) {
      const [mailService, apiKey, listId] = str.split(':');
      return mailService === this.key ? {mailService, apiKey, listId} : false;
    },
  },
  {
    label: 'MailerLite',
    key: 'mailerlite',
    display: {apiKey: true},
    encode(values) {
      return `${this.key}:${values.apiKey}`;
    },
    decode(str) {
      const [mailService, apiKey] = str.split(':');
      return mailService === this.key ? {mailService, apiKey} : false;
    },
  },
];

const ProjectForm = ({projectId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const project = useSelector(state => state.projects.items[projectId]);
  const isLoading = useSelector(state => Boolean(projectId && !project));

  if (isLoading) {
    return <CircularProgress />;
  }

  let title = 'Novo Projeto';
  let submitLabel = 'Criar';
  let submitFn = api.createProject;
  let postSubmitFn = () => history.navigate(`..`);
  let initialValues = {
    name: '',
    domains: '',
    mailService: 'none',
    apiKey: '',
    listId: '',
  };

  if (project) {
    title = 'Editar Projeto';
    submitLabel = 'Salvar';

    submitFn = async c =>
      await api.editProject(Object.assign(c, {projectId: project.id}));

    postSubmitFn = () => history.navigate(`/projects`);

    initialValues = cloneDeep(project);
    for (let ms of mailServices) {
      const mailerData = ms.decode(project.mailer);
      if (mailerData) {
        Object.assign(initialValues, mailerData);
        break;
      }
    }
  }

  initialValues.newUser = '';

  const msByKey = key => mailServices.filter(ms => ms.key === key)[0];

  return (
    <Layout>
      <Paper className={classes.paper}>
        <Title>{title}</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            const ms = msByKey(values.mailService);
            const resp = await submitFn({
              name: values.name,
              mailer: ms.encode(values),
              domains: values.domains,
              users: values.users,
            });
            dispatch({type: 'projectsOutdated'});
            setSubmitting(false);
            if (resp.error) {
              alert(resp.error);
            } else {
              postSubmitFn();
            }
          }}
        >
          {({isSubmitting, values, setFieldValue}) => {
            const ms = msByKey(values.mailService);

            const addUser = () => {
              setFieldValue('users', [
                ...values.users,
                {email: values.newUser},
              ]);
              setFieldValue('newUser', '');
            };

            return (
              <Form className={classes.root}>
                <Field
                  className={classes.field}
                  name="name"
                  component={TextField}
                  label="Nome"
                />

                <Field
                  className={classes.field}
                  name="mailService"
                  component={TextField}
                  label="Serviço de emails"
                  select
                >
                  {mailServices.map(ms => (
                    <MenuItem value={ms.key} key={ms.key}>
                      {ms.label}
                    </MenuItem>
                  ))}
                </Field>

                {ms.display.apiKey ? (
                  <Field
                    className={classes.field}
                    name="apiKey"
                    component={TextField}
                    label="API key"
                  />
                ) : null}

                {ms.display.listId ? (
                  <Field
                    className={classes.field}
                    name="listId"
                    component={TextField}
                    label="List ID"
                  />
                ) : null}

                <Field
                  className={classes.field}
                  name="domains"
                  component={TextField}
                  label="Domínios"
                />
                {project && project.users ? (
                  <div className={classes.users}>
                    <Typography variant="h6" className={classes.title}>
                      Usuários
                    </Typography>
                    <List dense={true}>
                      {values.users.map(u => (
                        <ListItem key={u.email}>
                          <ListItemAvatar>
                            <Avatar>
                              <Gravatar email={u.email} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={u.email} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                setFieldValue(
                                  'users',
                                  values.users.filter(i => i !== u)
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            {values.newUser !== '' ? (
                              <Gravatar email={values.newUser} />
                            ) : null}
                          </Avatar>
                        </ListItemAvatar>
                        <Field
                          className={classes.field}
                          name="newUser"
                          component={TextField}
                          label="Novo usuário (email)"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              addUser();
                              e.preventDefault();
                            }
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={addUser}
                          >
                            <PersonAdd />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </div>
                ) : null}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {submitLabel}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Layout>
  );
};

ProjectForm.propTypes = {
  projectId: PropTypes.string,
};

export default ProjectForm;
