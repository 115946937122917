import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Download from '@material-ui/icons/GetApp';

import {api} from 'services';
import {baseUrl} from 'services/request';
import Table from 'components/misc/Table';
import Layout from 'components/misc/Layout';

// Returns the number of unique campaign IDs in an array.
function uniqueIDs(array) {
  if (!array) {
    return 0;
  }

  let ids = {};
  for (let item of array) {
    if (item.campaignId) {
      ids[item.campaignId] = 1;
    }
  }
  return Object.keys(ids).length;
}

const MailSubscribers = ({projectId}) => {
  let isLoading = false;
  return (
    <Layout>
      <Table
        isLoading={isLoading}
        title="E-mails cadastrados"
        columns={[
          {title: 'E-mail', field: 'email'},
          {title: 'Status', field: 'status'},
          {title: 'Nome', field: 'name'},
          {
            title: 'Data',
            field: 'createdAt',
            render: row => dayjs(row.createdAt).format('DD/MM/YYYY'),
            defaultSort: 'desc',
          },
          {title: 'Entregas', field: 'deliveriesCount'},
          {title: 'Aberturas (%)', field: 'opensRate'},
          {title: 'Cliques (%)', field: 'clicksRate'},
        ]}
        data={async query => {
          let params = {
            projectId,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
          };
          if (query.orderBy) {
            params.sortField = query.orderBy['field'];
            params.sortDir = query.orderDirection === 'asc' ? 1 : -1;
          }
          isLoading = true;
          let r = await api.mailSubscribers(params);
          isLoading = false;

          let data = r.response.data || [];

          data.map(row => {
            row.name = row.data && row.data.name ? row.data.name : '';
            row.deliveriesCount = uniqueIDs(row.deliveries);

            row.opensRate = 0;
            let ids = uniqueIDs(row.opens);
            if (ids) {
              row.opensRate =
                (row.deliveriesCount ? ids / row.deliveriesCount : 1) * 100 +
                '%';
            }

            row.clicksRate = 0;
            ids = uniqueIDs(row.clicks);
            if (ids) {
              row.clicksRate =
                (row.deliveriesCount ? ids / row.deliveriesCount : 1) * 100 +
                '%';
            }

            return row;
          });

          return {
            data,
            page: r.response.page || 0,
            totalCount: r.response.totalCount || 0,
          };
        }}
        rowLinkTo={row => `${row.id}`}
        actions={[
          {
            icon: () => <Download />,
            tooltip: 'Baixar dados',
            onClick: (event, rowData) => {
              var form = document.createElement('form');
              form.action = `${baseUrl}/projects/${projectId}/mail_subscribers.csv`;
              form.method = 'post';
              form.target = '_blank';

              var tokenInput = document.createElement('input');
              tokenInput.type = 'hidden';
              tokenInput.value = window.localStorage.token;
              tokenInput.name = 'token';
              form.appendChild(tokenInput);

              document.body.appendChild(form);
              form.submit();
              document.body.removeChild(form);
            },
            isFreeAction: true,
          },
        ]}
      />
    </Layout>
  );
};

MailSubscribers.propTypes = {
  projectId: PropTypes.string,
};

export default MailSubscribers;
