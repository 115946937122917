import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Delete from '@material-ui/icons/Delete';
import Save from '@material-ui/icons/Save';
import Mail from '@material-ui/icons/Mail';
import Schedule from '@material-ui/icons/Schedule';
import DeviceHub from '@material-ui/icons/DeviceHub';
import {makeStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import CampaignForm from 'components/mailcamp/CampaignForm';
import Conditional from 'components/automation/Conditional';
import Step from 'components/automation/Step';
import Layout from 'components/misc/Layout';
import {api} from 'services';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

function closeMenu(ctx) {
  ctx.setMenuTarget(null);
}

// Add a new step between the selected step and its children.
// The new child becomes parent of the old children.
function replaceChildren(ctx, newStep) {
  let parent = ctx.selectedStep || ctx.pipeline;

  let parentArray;
  if (ctx.menuTarget.getArray) {
    parentArray = ctx.menuTarget.getArray(parent);
  }
  if (!parentArray) parentArray = parent.children;

  newStep.children = parentArray ? [...parentArray] : [];
  if (parent.tree) newStep.children.push(parent.tree);

  if (newStep.condition) {
    if (
      newStep.children.length &&
      !window.confirm(
        'Isso irá remover todos os blocos abaixo deste, continuar?'
      )
    ) {
      return null;
    }
    newStep.children = [];
  }

  if (parentArray) {
    parentArray.length = 0;
    parentArray.push(newStep);
  } else {
    parent.tree = newStep;
  }

  setIds(ctx.pipeline, 0);
  ctx.setSelectedId(newStep.id);
  ctx.setPipeline();
}

// Removes the selected step and all its decendants.
function removeSubtree(ctx) {
  if (
    !window.confirm(
      'Isso irá remover este bloco e todos os abaixo dele, continuar?'
    )
  ) {
    return null;
  }

  let parentArray;
  if (ctx.menuTarget.getParentArray)
    parentArray = ctx.menuTarget.getParentArray(ctx.selectedParent);
  if (!parentArray) parentArray = ctx.selectedParent.children;

  if (parentArray) {
    parentArray.splice(parentArray.indexOf(ctx.selectedStep), 1);
  } else {
    ctx.selectedParent.tree = null;
  }
  ctx.setPipeline();
  ctx.setSelectedId(null);
}

async function savePipeline(ctx) {
  const p = JSON.parse(JSON.stringify(ctx.pipeline));
  setIds(p, null);
  if (ctx.pipeline.id) {
    await api.editPipeline(p);
  } else {
    await api.createPipeline(p);
  }
  ctx.dispatch({type: 'pipelinesOutdated'});
  // TODO: If one day 'pipelinesOutdated' stop being resolved by /all, it may be
  // necessary to uncomment the line below, because the CampaignForm is not
  // dispatching it.
  // ctx.dispatch({type: 'campaignsOutdated'});
}

async function deletePipeline(ctx) {
  if (!window.confirm('Deletar toda a automação para este formulário?')) {
    return null;
  }

  await api.deletePipeline(ctx.pipeline.id);
  ctx.dispatch({type: 'pipelinesOutdated'});
}

// Return a list of a step children.
function getChildren(step) {
  let children = step.tree ? [step.tree] : [];
  if (step.children) children = [...step.children];
  if (step.condition)
    children = [...children, ...step.condition.true, ...step.condition.false];
  return children;
}

// Retuns a step and its parent. First parameter should be the pipeline.
function findStep(step, id, parent) {
  if (step.id === id) return {step, parent};

  for (let c of getChildren(step)) {
    if (!c) continue;
    const found = findStep(c, id, step);
    if (found) return found;
  }

  return null;
}

// Set ids for all steps of a pipeline.
function setIds(step, id) {
  if (!step.children) {
    if (!step.tree) {
      return null;
    }
    step = step.tree;
  }

  if (Number.isInteger(id)) {
    step.id = id++;
  } else {
    delete step.id;
  }
  for (let c of getChildren(step)) {
    id = setIds(c, id);
  }
  return id;
}

const Pipeline = ({projectId, channelId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const channel = useSelector(state => state.channels.items[channelId]);
  let pipeline = useSelector(
    state => {
      for (let p of Object.values(state.pipelines.items)) {
        if (p.channelId === channelId) {
          p = JSON.parse(JSON.stringify(p));
          setIds(p, 0);
          return p;
        }
      }
      return null;
    },
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );

  const [menuTarget, setMenuTarget] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  if (!pipeline && channel) {
    pipeline = {
      channelId: channel.id,
      projectId,
      tree: null,
    };
  }

  if (!channel || !pipeline) {
    return <CircularProgress />;
  }

  const {step: selectedStep, parent: selectedParent} =
    findStep(pipeline, selectedId) || {};

  const ctx = {
    dispatch,
    pipeline,
    menuTarget,
    setMenuTarget,
    setSelectedId,
    selectedStep,
    selectedParent,
    setPipeline() {
      dispatch({type: 'setPipeline', payload: pipeline});
    },
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            {channel.name}
          </Typography>
          <IconButton
            color="inherit"
            title="salvar"
            onClick={() => savePipeline(ctx)}
          >
            <Save />
          </IconButton>
          {ctx.pipeline.id ? (
            <IconButton
              color="inherit"
              title="deletar"
              onClick={() => deletePipeline(ctx)}
            >
              <Delete />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
      <Layout>
        <Grid className={classes.content} container spacing={3}>
          <Grid item xs={6}>
            <Step step={pipeline} ctx={ctx} />
          </Grid>
          <Grid item xs={6}>
            {selectedStep && selectedStep.action ? (
              <CampaignForm
                type="transactional"
                continuous={true}
                key={selectedStep.action.mcId}
                channel={channel}
                campaignId={selectedStep.action.mcId}
                hookCampCreated={id => {
                  selectedStep.action.mcId = id;
                  savePipeline(ctx);
                }}
              />
            ) : null}

            {selectedStep && selectedStep.condition ? (
              <Conditional
                cmpVal="event"
                projectId={projectId}
                condition={selectedStep.condition}
                onChange={() => ctx.setPipeline()}
              />
            ) : null}
          </Grid>
        </Grid>
      </Layout>

      {menuTarget ? (
        <Menu
          id="simple-menu"
          anchorEl={menuTarget.elem}
          open={Boolean(menuTarget)}
          onClose={() => {
            closeMenu(ctx);
          }}
        >
          {selectedParent && !menuTarget.getArray ? (
            <MenuItem
              onClick={() => {
                removeSubtree(ctx);
                closeMenu(ctx);
              }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Remover caminho" />
            </MenuItem>
          ) : null}

          {(selectedStep && !selectedStep.condition) || menuTarget.getArray
            ? [
                <MenuItem
                  key="camp"
                  onClick={() => {
                    replaceChildren(ctx, {action: {mcId: null}});
                    closeMenu(ctx);
                  }}
                >
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText primary="Adicionar campanha" />
                </MenuItem>,
                <MenuItem
                  key="delay"
                  onClick={() => {
                    replaceChildren(ctx, {delay: {duration: 0}});
                    closeMenu(ctx);
                  }}
                >
                  <ListItemIcon>
                    <Schedule />
                  </ListItemIcon>
                  <ListItemText primary="Adicionar tempo de espera" />
                </MenuItem>,
                <MenuItem
                  key="cond"
                  onClick={() => {
                    replaceChildren(ctx, {
                      condition: {filter: {}, true: [], false: []},
                    });
                    closeMenu(ctx);
                  }}
                >
                  <ListItemIcon>
                    <DeviceHub />
                  </ListItemIcon>
                  <ListItemText primary="Adicionar condição" />
                </MenuItem>,
              ]
            : null}
        </Menu>
      ) : null}
    </>
  );
};

Pipeline.propTypes = {
  pipeline: PropTypes.object,
};

export default Pipeline;
