import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, shallowEqual} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import dayjs from 'dayjs';
import {Link} from '@reach/router';

import {makeStyles} from '@material-ui/core/styles';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Table from 'components/misc/Table';
import Layout from 'components/misc/Layout';
import {eventTypes} from 'components/mailsub/MailSubscriberView';
import {timestampFromObjectId} from 'utils';
import {campStatus} from 'translations';

const useStyles = makeStyles(theme => ({
  cell: {
    marginLeft: 'auto',
    marginRight: '20px',
  },
}));

const countsFields = [
  'bounces',
  'clicks',
  'complaints',
  'deliveries',
  'opens',
  'unsubscribes',
];

const MailCampaigns = ({projectId}) => {
  const classes = useStyles();
  const campaigns = useSelector(
    state =>
      Object.values(state.campaigns.items).filter(
        c => c.projectId === projectId
      ),
    shallowEqual
  );
  const isLoading = useSelector(state => state.campaigns.isLoading);

  const tooltip = event => {
    return rowData => (
      <Tooltip className={classes.cell} title={rowData[event]}>
        <Link to={`${rowData.id}/${eventTypes[event].singular}`}>
          <span>{rowData[event + 'Perc']}</span>
        </Link>
      </Tooltip>
    );
  };

  return (
    <Layout>
      <Table
        isLoading={isLoading}
        title="Campanhas"
        columns={[
          {title: 'Assunto', field: 'subject'},
          {title: 'Nome_', field: 'name_', hidden: true, searchable: true},
          {
            title: 'Envio',
            field: 'date',
            defaultSort: 'desc',
            customSort: (a, b) =>
              (a.sentTime || a.createdAt) - (b.sentTime || b.createdAt),
          },
          {
            title: 'Entregas',
            field: 'deliveries',
            render: tooltip('deliveries'),
            type: 'numeric',
          },
          {
            title: 'Aberturas',
            field: 'opens',
            render: tooltip('opens'),
            type: 'numeric',
          },
          {
            title: 'Cliques',
            field: 'clicks',
            render: tooltip('clicks'),
            type: 'numeric',
          },
          {
            title: 'Retornos',
            field: 'bounces',
            render: tooltip('bounces'),
            type: 'numeric',
          },
          {
            title: 'Desinscrições',
            field: 'unsubscribes',
            render: tooltip('unsubscribes'),
            type: 'numeric',
          },
          {
            title: 'Reclamações',
            field: 'complaints',
            render: tooltip('complaints'),
            type: 'numeric',
          },
        ]}
        data={campaigns.map(c => {
          const createdAt = timestampFromObjectId(c.id);
          const row = {
            subject: c.subject,
            name_: c.subject.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            projectId: c.projectId,
            createdAt,
            sentTime: c.sentTime && dayjs(c.sentTime),
            id: c.id,
          };

          // Sent date or status.
          row.date = c.status;
          if (c.continuous) {
            row.date = 'contínua';
          } else if (c.status === 'sent' && c.sentTime) {
            row.date = row.sentTime.format('DD/MM/YYYY');
          }
          row.date = campStatus[row.date] || row.date;

          for (let k of countsFields) {
            let v = c.counts[k] || 0;
            row[k] = v;
            if (k !== 'deliveries') {
              if (v > 0) {
                v = (v / c.counts.deliveries) * 100;
                v = v < 1 ? v.toFixed(2) : v.toFixed(0);
              }
              v = (v + '%').replace('.', ',');
            }
            row[k + 'Perc'] = v;
          }

          return row;
        })}
        rowLinkTo={row => `${row.id}`}
        actions={[
          {
            icon: () => <NoteAdd />,
            tooltip: 'Criar Campanha',
            isFreeAction: true,
            href: () => `new`,
            onClick: () => {},
          },
        ]}
      />
    </Layout>
  );
};

MailCampaigns.propTypes = {
  projectId: PropTypes.string,
};

export default MailCampaigns;
