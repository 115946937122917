import React, {useEffect} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import Gravatar from 'react-gravatar';
import Paper from '@material-ui/core/Paper';
import Edit from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {AvatarGroup} from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';
import dayjs from 'dayjs';

import {history} from 'services';
import Table from 'components/misc/Table';
import Layout from 'components/misc/Layout';

const Projects = () => {
  let projects = useSelector(state => {
    let projects = state.projects.items;
    let pp = Object.values(projects);
    if (pp.length) {
      for (let p of pp) {
        p.chanCount = 0;
      }
      for (let c of Object.values(state.channels.items)) {
        if (projects[c.projectId]) {
          projects[c.projectId].chanCount++;
        }
      }
    }
    return projects;
  }, shallowEqual);
  const isSuper = useSelector(state => state.user.role === 'super');
  const isLoading = useSelector(state => state.projects.isLoading);

  useEffect(() => {
    if (Object.keys(projects).length === 1) {
      history.navigate(`/projects/${Object.keys(projects)[0]}/channels`);
    }
  }, [projects]);

  const columns = [
    {
      title: 'Nome',
      field: 'name',
    },
    {title: 'Nome_', field: 'name_', hidden: true, searchable: true},
    {
      title: 'Data',
      field: 'date',
      defaultSort: 'desc',
      customSort: (a, b) => a.createdAt - b.createdAt,
    },
    {title: 'Formulários', field: 'chanCount'},
  ];

  if (isSuper) {
    columns.push({
      title: 'Usuários',
      field: 'users',
      render: rowData => (
        <AvatarGroup max={8}>
          {rowData.users
            ? rowData.users.map((u, i) => (
                <Tooltip title={u.email} key={i}>
                  <Avatar alt={u.email}>
                    <Gravatar email={u.email} />
                  </Avatar>
                </Tooltip>
              ))
            : null}
        </AvatarGroup>
      ),
    });
  }

  return (
    <Layout>
      <Paper>
        <Table
          isLoading={isLoading}
          title="Projetos"
          columns={columns}
          data={Object.values(projects).map(c => {
            return {
              name: c.name,
              name_: c.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
              date: c.createdAt.split('T')[0],
              createdAt: dayjs(c.createdAt),
              id: c.id,
              chanCount: c.chanCount,
              users: c.users,
            };
          })}
          rowLinkTo={row => `/projects/${row.id}/channels`}
          actions={
            isSuper
              ? [
                  {
                    icon: () => <NoteAdd />,
                    tooltip: 'Criar Projeto',
                    isFreeAction: true,
                    href: () => `new`,
                    onClick: () => {},
                  },
                  {
                    icon: () => <Edit />,
                    tooltip: 'Editar',
                    href: row => `${row.id}/edit`,
                    onClick: () => {},
                  },
                ]
              : null
          }
        />
      </Paper>
    </Layout>
  );
};

export default Projects;
