import React from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import {htmlToText} from 'html-to-text';
import {withStyles} from '@material-ui/core/styles';

import initialComponent from './initialComp';
import initialStyle from './initialStyle';
import mjVideo from './videoPlugin';

const useStyles = theme => ({
  editor: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1500,
    position: 'absolute',
  },
  '@global': {
    '.gjs-pn-devices-c': {
      marginLeft: '25%',
    },
  },
});

class Editor extends React.Component {
  static defaultProps = {
    id: 'grapesjs-editor',
  };

  componentDidMount() {
    const {
      id,
      editorData,
      setFieldValue,
      setShowEditor,
      reloadTemplate,
      handleSubmit,
      readOnly,
    } = this.props;
    const self = this;

    let components = initialComponent;
    let style = initialStyle;
    if (editorData) {
      if (editorData.components) components = JSON.parse(editorData.components);
      if (editorData.style) style = JSON.parse(editorData.style);
    }

    const editor = grapesjs.init({
      container: `#${id}`,
      components,
      style,
      fromElement: false,
      blockManager: {},
      styleManager: {},
      storageManager: {
        id: '',
        type: 'evag',
        autosave: false,
        autoload: false,
        storeHtml: false,
        storeCss: false,
      },
      height: null,
      width: null,
      avoidInlineStyle: true,
      plugins: ['grapesjs-mjml', mjVideo],
      pluginsOpts: {
        'grapesjs-mjml': {},
      },
    });

    self.setState({
      editor,
    });

    window.e = editor;

    // Fix: https://github.com/artf/grapesjs-mjml/issues/149#issuecomment-785533900
    let root = editor.DomComponents.getWrapper();
    let searchAndUpdate = function (component, layer = 0) {
      if (component.get('tagName') === 'mj-image') {
        component.set('void', false);
      }
      let children = component.get('components');
      if (children) {
        children.each(child => searchAndUpdate(child, layer + 1));
      }
    };
    searchAndUpdate(root);
    editor.on('component:add', function (model) {
      model.set('void', false);
    });
    // ------------------------------------------------------------------------

    ['text', 'textnode', 'mj-text'].forEach(s => {
      editor.DomComponents.addType(s, {
        model: {
          defaults: {
            'style-default': {
              padding: '10px 25px 10px 25px',
              'font-size': '16px',
              'line-height': '24px',
              align: 'left',
            },
          },
        },
      });
    });

    // Change image selector dialog. This one is simpler and only allows one URL.
    editor.DomComponents.addType('mj-image', {
      view: {
        events: {
          click: function () {
            let url = this.model.getAttributes().src || '';
            url = prompt('Entre com o link para a imagem:', url) || url;
            console.log(url);
            this.model.set('src', url);
            window.m = this;
          },
        },
      },
    });

    editor.StorageManager.add('evag', {
      async store(data, clb, clbErr) {
        self.setState({modified: true});
        // TODO: mjml-get-code returns an "error" field besides "html", must check?
        let html = editor.runCommand('mjml-get-code').html;
        let text = htmlToText(html, {wordwrap: 80});
        setFieldValue('editing', true);
        setFieldValue('body', {
          editor: data,
          html,
          text,
        });

        try {
          await handleSubmit();
          clb();
        } catch (e) {
          clbErr(e);
        }
      },
    });

    // Closes the editor.
    function close() {
      setShowEditor(false);
      setFieldValue('editing', false);
      if (self.state.modified) {
        reloadTemplate();
      }
    }

    let saveBtns = [];
    if (!readOnly) {
      saveBtns = [
        {
          id: 'save-and-close',
          className: 'fa fa-check',
          attributes: {title: 'Save and Close'},
          active: false,
          async command() {
            await editor.store();
            self.setState({modified: false});
            close();
          },
        },
        {
          id: 'save',
          className: 'fa fa-floppy-o',
          attributes: {title: 'Save'},
          active: false,
          command: editor.store,
        },
      ];
    }

    editor.Panels.addPanel({
      id: 'store-panel',
      visible: true,
      buttons: [
        ...saveBtns,
        {
          id: 'close',
          className: 'fa fa-ban',
          attributes: {title: 'Close'},
          active: false,
          command: close,
        },
      ],
    });
  }

  render() {
    const {id, classes} = this.props;

    return (
      <div
        id={id}
        className={classes.editor}
        onClick={e => {
          // Avoid buttons from inside the editor to trigger unwanted form submits.
          e.preventDefault();
        }}
      />
    );
  }
}

export default withStyles(useStyles)(Editor);
