import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ChannelsList from 'components/channel/ChannelsList';
import ChannelForm from 'components/channel/ChannelForm';
import Layout from 'components/misc/Layout';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const Channels = ({projectId}) => {
  const classes = useStyles();
  return (
    <Layout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ChannelsList projectId={projectId} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <ChannelForm projectId={projectId} />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Channels;
