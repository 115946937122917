import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import Gravatar from 'react-gravatar';

import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Delivery from '@material-ui/icons/Send';
import Subscribe from '@material-ui/icons/PersonAdd';
import Unsubscribe from '@material-ui/icons/Cancel';
import Complaint from '@material-ui/icons/Report';
import Bounce from '@material-ui/icons/Undo';
import Click from '@material-ui/icons/TouchApp';
import Open from '@material-ui/icons/Drafts';
import {green, yellow, red} from 'components/styles';

import {api} from 'services';
import Layout from 'components/misc/Layout';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerItem: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: '100%',
    marginLeft: theme.spacing(-2),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  item: {
    margin: '0',
  },
  ul: {
    paddingLeft: theme.spacing(2),
    marginTop: 0,
    maxWidth: '100%',
  },
  ulUnde: {
    paddingLeft: theme.spacing(0),
    listStyle: 'none',
    marginTop: 0,
  },
}));

export const eventTypes = {
  deliveries: {
    icon: <Delivery />,
    label: 'entrega',
    color: green,
    descr: 'Recebeu e-mail {campName}.',
    singular: 'delivery',
  },
  subscribes: {
    icon: <Subscribe />,
    label: 'inscrição',
    color: green,
    descr: 'Pediu para receber e-mails no formulário {chanName}.',
    singular: 'subscribe',
  },
  unsubscribes: {
    icon: <Unsubscribe />,
    label: 'desinscrição',
    color: red,
    descr: 'Saiu da lista pelo e-mail {campName}.',
    singular: 'unsubscribe',
  },
  complaints: {
    icon: <Complaint />,
    label: 'reclamação',
    color: red,
    descr: 'Reportou SPAM ao receber e-mail {campName}.',
    singular: 'complaint',
  },
  bounces: {
    icon: <Bounce />,
    label: 'bounce',
    color: red,
    descr: 'O e-mail {campName} voltou.',
    singular: 'bounce',
  },
  clicks: {
    icon: <Click />,
    label: 'clique',
    color: green,
    descr: 'Clicou no link {url} no e-mail {campName}.',
    singular: 'click',
  },
  opens: {
    icon: <Open />,
    label: 'abertura',
    color: green,
    descr: 'Abriu e-mail {campName}.',
    singular: 'open',
  },
};

const statusColors = {
  subscribed: green,
  not_subscribed: yellow,
  unsubscribed: red,
  bounced: red,
  complained: red,
};

const MailSubscriber = ({projectId, mailSubscriberId}) => {
  const classes = useStyles();

  const channels = useSelector(state => state.channels.items);
  const campaigns = useSelector(state => state.campaigns.items);

  const [ms, setMS] = useState(null);

  let isLoading = false;

  if (!ms && !isLoading) {
    isLoading = true;
    (async () => {
      let {response} = await api.mailSubscriber({projectId, mailSubscriberId});

      response.events = [];
      for (let [k, v] of Object.entries(response)) {
        if (k in eventTypes) {
          response.events = response.events.concat(
            v.map(i => {
              i.type = k;
              i.date = dayjs(i.date);
              return i;
            })
          );
        }
      }
      response.events.sort((a, b) => (a.date.isAfter(b.date) ? -1 : 1));

      response.statusColor = setMS(response);

      isLoading = false;
    })();
  }

  if (isLoading) return <CircularProgress />;

  const formatDesc = (str, data) => {
    if (!str) return '';

    if (campaigns && data.campaignId) {
      let camp = campaigns[data.campaignId];
      if (camp) {
        data.campName = camp.subject;
      }
    }

    if (channels && data.channelId) {
      let chan = channels[data.channelId];
      if (chan) {
        data.chanName = chan.name;
      }
    }

    return str
      .replace(/\{(\w+)\}/g, function (a, b) {
        return data[b];
      })
      .replace(/ undefined/g, '');
  };

  return (
    <Layout>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Avatar className={classes.headerItem}>
            <Gravatar email={ms.email} />
          </Avatar>
          <h1 className={classes.headerItem}>{ms.email}</h1>
          <Chip
            className={classes.headerItem}
            label={ms.status}
            style={{color: 'white', backgroundColor: statusColors[ms.status]}}
          />
        </div>

        {channels && ms.channels && ms.channels.length ? (
          <>
            <h2>Formulários preenchidos</h2>
            <ul className={classes.ul}>
              {ms.channels.map(c => (
                <li key={c}>{channels[c] ? channels[c].name : c}</li>
              ))}
            </ul>
          </>
        ) : null}

        <h2>Dados</h2>
        <ul className={classes.ulUnde}>
          {Object.entries(ms.data || []).map(([k, v]) => (
            <li key={k}>
              <b>{k}:</b> {v}
            </li>
          ))}
        </ul>

        <h2 style={{marginBottom: 0, marginTop: '30px'}}>Linha do tempo</h2>
        <List className={classes.list}>
          {ms.events.map(e => (
            <ListItem key={e.date}>
              <ListItemAvatar>
                <Avatar style={{backgroundColor: eventTypes[e.type].color}}>
                  {eventTypes[e.type].icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={formatDesc(eventTypes[e.type].descr, e)}
                secondary={e.date.format('DD/MM/YYYY HH:mm')}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Layout>
  );
};

MailSubscriber.propTypes = {
  projectId: PropTypes.string,
  mailSubscriberId: PropTypes.string,
};

export default MailSubscriber;
