import {cloneDeep} from 'lodash';

const itemBased = {
  state: {
    isLoading: false,
    items: {},
  },

  reducer: {
    loading() {
      return {isLoading: true};
    },
    endLoading() {
      return {isLoading: false};
    },
    failed() {
      return {isLoading: false};
    },
    // putItems doesn't set isLoading to false because the request response may
    // contain data for multiple models (so multiple putItems will happen), only
    // one should change isLoading to false, and only after all putItems are
    // processed
    putItems({payload}) {
      return {
        // items: {...state.items, ...payload},
        items: payload,
      };
    },
    setItem({state, payload}) {
      return {
        items: {...state.items, [payload.id]: payload},
      };
    },
  },
};

export default {
  constants: cloneDeep(itemBased),
  channels: cloneDeep(itemBased),
  fields: cloneDeep(itemBased),
  groups: cloneDeep(itemBased),
  projects: cloneDeep(itemBased),
  templates: cloneDeep(itemBased),
  campaigns: cloneDeep(itemBased),
  pipelines: cloneDeep(itemBased),

  user: {
    state: {
      isLoading: false,
      isLoggedIn: undefined,
      email: null,
      name: null,
      picture: null,
      role: null,
    },

    reducer: {
      loading() {
        return {isLoading: true};
      },
      failed() {
        return {isLoading: false};
      },
      set({payload}) {
        return {
          isLoading: false,
          isLoggedIn: true,
          ...payload,
        };
      },
      clear({initialState}) {
        return {
          ...initialState,
          isLoggedIn: false,
        };
      },
    },
  },
};
