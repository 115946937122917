import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {Link} from '@reach/router';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Assignment from '@material-ui/icons/Assignment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drafts from '@material-ui/icons/Drafts';
import Folder from '@material-ui/icons/Folder';
import Subscribers from '@material-ui/icons/ContactMail';
import Send from '@material-ui/icons/Send';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {history} from 'services';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  picture: {
    height: 25,
    borderRadius: 100,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  paper: {
    justifyContent: 'space-between',
  },
  menuItem: {
    padding: 0,
  },
  menuLink: {
    padding: '5px 10px',
    display: 'block',
    width: '100%',
  },
}));

const MainMenu = ({projectId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const userName = useSelector(state => state.user.name);
  const userPicture = useSelector(state => state.user.picture);
  const projects = useSelector(state => state.projects.items);

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div>
        <List>
          <ListItem>
            <ListItemIcon>
              <img className={classes.picture} src={userPicture} alt="avatar" />
            </ListItemIcon>
            <ListItemText primary={userName} />
          </ListItem>

          <ListItem
            button
            key={'logout'}
            onClick={() => dispatch({type: 'logoutClicked'})}
          >
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>

        <Divider />

        {Object.keys(projects).length > 1 ? (
          <>
            <List>
              <ListItem
                button
                key={'projects'}
                aria-controls="projects-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <ListItemIcon>
                  <Folder />
                </ListItemIcon>
                <ListItemText
                  primary={
                    projects && projects[projectId]
                      ? projects[projectId].name
                      : 'Projetos'
                  }
                />
              </ListItem>
            </List>

            <Menu
              id="projects-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className={classes.menuItem} key="root-projetos">
                <Link className={classes.menuLink} to={`/projects`}>
                  Projetos
                </Link>
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                key="root-edit"
                onClick={() => {
                  handleClose();
                  history.navigate(`/projects/${projectId}/edit`);
                }}
              >
                <Link
                  className={classes.menuLink}
                  to={`/projects/${projectId}/edit`}
                >
                  Editar
                </Link>
              </MenuItem>

              <Divider />

              {Object.values(projects).map(p => (
                <MenuItem
                  className={classes.menuItem}
                  key={p.id}
                  onClick={() => {
                    handleClose();
                    history.navigate(`/projects/${p.id}/channels`);
                  }}
                >
                  <Link
                    className={classes.menuLink}
                    to={`/projects/${p.id}/channels`}
                  >
                    {p.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>

            <Divider />
          </>
        ) : null}

        <List>
          <Link to={`/projects/${projectId}/channels`}>
            <ListItem button key={'canais'}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText primary={'Formulários'} />
            </ListItem>
          </Link>

          <Link to={`/projects/${projectId}/mail_subscribers`}>
            <ListItem button key={'subscribers'}>
              <ListItemIcon>
                <Subscribers />
              </ListItemIcon>
              <ListItemText primary={'Assinantes'} />
            </ListItem>
          </Link>

          <Link to={`/projects/${projectId}/mail_campaigns`}>
            <ListItem button key={'mail_campaigns'}>
              <ListItemIcon>
                <Send />
              </ListItemIcon>
              <ListItemText primary={'Campanhas'} />
            </ListItem>
          </Link>

          <Link to={`/projects/${projectId}/templates`}>
            <ListItem button key={'templates'}>
              <ListItemIcon>
                <Drafts />
              </ListItemIcon>
              <ListItemText primary={'Templates'} />
            </ListItem>
          </Link>
        </List>
      </div>
      <List>
        <ListItem button onClick={() => setOpen(!open)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ListItem>
      </List>
    </Drawer>
  );
};

MainMenu.propTypes = {
  projectId: PropTypes.string,
};

export default MainMenu;
