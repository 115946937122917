import models from './models';

// Create a reducer based on a model description
function createReducer(modelName, model) {
  return (state, action) => {
    // in the first run, return initial state
    if (state === undefined) {
      return model.state;
    }

    // parse the action type
    const [actionModelName, mutation] = action.type.split('/');

    // if the action is not directed to this model, just return the previous state
    if (actionModelName !== modelName) {
      return state;
    }

    if (!model.reducer[mutation]) {
      console.error(`No mutation ${mutation} in ${modelName}`);
      return state;
    }

    // process the action
    const change = model.reducer[mutation]({
      state,
      payload: action.payload,
      initialState: model.state,
    });
    return {...state, ...change};
  };
}

// Create reducers for all the models
function createReducers(modelsObj) {
  const reducers = {};
  Object.entries(modelsObj).forEach(([name, model]) => {
    reducers[name] = createReducer(name, model);
  });
  return reducers;
}

export default createReducers(models);
