export function processTokenInHash() {
  const rawToken = window.location.hash.slice(1);
  if (!rawToken) {
    return null;
  }
  window.location.hash = '';
  setToken(rawToken);
  return getParsedToken();
}

export function setToken(token) {
  window.localStorage.setItem('token', token);
}

export function removeToken() {
  window.localStorage.removeItem('token');
}

export function isLoggedIn() {
  // TODO: check if token is valid
  if (getRawToken()) {
    return true;
  }
  return false;
}

export function getParsedToken() {
  const raw = getRawToken();
  if (!raw) {
    return null;
  }
  const rawJson = atob(raw.split('.')[1]);
  return JSON.parse(rawJson);
}

export function getRawToken() {
  return window.localStorage.getItem('token');
}

export default {
  processTokenInHash,
  removeToken,
  isLoggedIn,
  getParsedToken,
  getRawToken,
  setToken,
};
