import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {Helmet} from 'react-helmet';
import {Formik, Form, Field} from 'formik';
import {TextField, Select as SelectFMU} from 'formik-material-ui';
import {cloneDeep, isEqual} from 'lodash';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import DateTimePicker from '@material-ui/lab/DateTimePicker';

import Layout from 'components/misc/Layout';
import Title from 'components/misc/Title';
import TemplateEditor from 'components/template/TemplateEditor';
import Conditional from 'components/automation/Conditional';
import {api, history} from 'services';
import {campStatus} from 'translations';
import useMainStyles, {green, yellow, red} from 'components/styles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  destSel: {
    marginBottom: '10px',
  },
  pasteArea: {
    width: '100%',
  },
  btn: {
    flexGrow: 1,
  },
  subForm: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const allFilter = {
  mailSubscriber: {
    cmp: 'eq',
    key: 'status',
    value: 'subscribed',
  },
};

// Without campaign this is a campaign creation form, with campaign this is a
// campaign edit form.
const CampaignForm = ({
  campaignId,
  projectId,
  channel,
  hookCampCreated,
  type,
  continuous,
}) => {
  const mainClasses = useMainStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const userEmail = useSelector(state => state.user.email);
  const templates = useSelector(state => state.templates.items);
  const campaign = useSelector(state => state.campaigns.items[campaignId]);
  const isLoading = useSelector(state =>
    Boolean((campaignId && !campaign) || state.campaigns.isLoading)
  );

  const [selDest, setSelDest] = useState('none');
  const [scheduled, setScheduled] = useState();
  const [sendTest, setSendTest] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [rcpCounts, setRcpCounts] = useState();

  useEffect(() => {
    if (isLoading || !campaign) {
      return;
    }
  }, [isLoading, campaign]);

  if (isLoading || (campaignId && !campaign)) {
    return <CircularProgress />;
  }

  if (selDest === 'none' && campaign) {
    if (campaign.destList) {
      setSelDest('paste');
    } else if (campaign.destFilter) {
      if (isEqual(allFilter, campaign.destFilter)) {
        setSelDest('all');
      } else {
        setSelDest('filter');
      }
    }
  }

  if (scheduled === undefined) {
    if (campaign && campaign.scheduledTime) {
      setScheduled(dayjs(campaign.scheduledTime));
    } else {
      setScheduled(false);
    }
  }

  let title = 'Nova Campanha';
  let submitLabel = 'Criar';
  let submitFn = api.createCampaign;
  let initialValues = {
    type,
    continuous,
    to: '',
    from: '',
    replyTo: '',
    subject: '',
    preHeader: '',
    templateId: '',
    destList: null,
    destFilter: null,
    status: 'draft',
  };
  let postSubmitFn = response => {
    if (hookCampCreated) hookCampCreated(response.ID);
    if (!continuous) history.navigate(response.ID);
  };

  // New campaign form
  if (channel) {
    Object.assign(initialValues, {
      projectId: channel.projectId,
      channelId: channel.id,
    });
  } else if (projectId) {
    initialValues.projectId = projectId;
  }

  // Edit campaign form
  if (campaign) {
    title = 'Editar Campanha';
    submitLabel = 'Salvar';
    submitFn = async c => api.editCampaign({...c, campaignId: campaign.id});
    postSubmitFn = null;
    initialValues = {
      ...cloneDeep(campaign),
      to: campaign.to ? campaign.to.join(', ') : '',
      sendTestTo: userEmail,
    };
  }

  if (!initialValues.destFilter) {
    initialValues.destFilter = {filter: {}};
  }

  function reloadCampaign() {
    dispatch({type: 'campaignsOutdated'});
  }

  const Frame = !continuous ? Layout : React.Fragment;

  let readOnly =
    campaign && campaign.status !== undefined && campaign.status !== 'draft';

  const submit = async ({editing, ...values}) => {
    if (readOnly) return;

    var data = cloneDeep(values);

    if (data.templateId) {
      data.body = templates[data.templateId].body;
    }
    delete data.templateId;

    // Prepare "to" field.
    data.to = data.to
      .split(',')
      .map(s => s.trim())
      .filter(s => s.length);

    // Prepare test address.
    if (sendTest) {
      data.sendTestTo = data.sendTestTo.split(',');
    } else {
      delete data.sendTestTo;
    }

    if (selDest !== 'paste') {
      data.destList = null;
    }
    if (selDest !== 'filter') {
      data.destFilter = null;
    }
    if (selDest === 'all') {
      data.destFilter = allFilter;
    }

    if (!scheduled) {
      data.scheduledTime = null;
    } else {
      data.scheduledTime = scheduled.format('YYYY-MM-DD[T]HH:mm:00Z');
    }

    let r = await submitFn(data);
    if (!editing) reloadCampaign();
    if (postSubmitFn) postSubmitFn(r.response);

    return r;
  };

  // Label used for the stop button.
  const stopLabel = ['starting', 'sending'].includes(
    campaign && campaign.status
  )
    ? 'Parar'
    : 'Reiniciar';

  const statusColors = {
    draft: green,
    starting: yellow,
    sending: yellow,
    stopping: yellow,
    error: red,
    sent: green,
  };

  return (
    <Frame>
      <Grid item xs={12}>
        <Helmet>
          {/* TODO: Remove script tag after this is fixed https://github.com/artf/grapesjs-mjml/issues/155 */}
          <script src="https://unpkg.com/grapesjs-mjml@0.6.0" />
        </Helmet>
        <Paper className={mainClasses.paper}>
          <div className={classes.header}>
            <Title>{title}</Title>
            {campaign ? (
              <Chip
                label={campStatus[campaign.status]}
                style={{
                  color: 'white',
                  backgroundColor: statusColors[campaign.status],
                }}
              />
            ) : null}
          </div>

          <Formik initialValues={initialValues} onSubmit={submit}>
            {({isSubmitting, handleSubmit, setFieldValue, values}) => (
              <Form className={mainClasses.root}>
                <Field
                  className={mainClasses.field}
                  name="subject"
                  component={TextField}
                  label="Assunto"
                  InputProps={{readOnly}}
                />
                {campaignId ? (
                  <div className={classes.subForm}>
                    <Field
                      className={mainClasses.field}
                      name="preHeader"
                      component={TextField}
                      label="Pré-Visualização"
                      InputProps={{readOnly}}
                    />
                    <Field
                      className={mainClasses.field}
                      name="from"
                      component={TextField}
                      label="De"
                      InputProps={{readOnly}}
                    />
                    <Field
                      className={mainClasses.field}
                      name="to"
                      component={TextField}
                      label="Para"
                      InputProps={{readOnly}}
                      helperText='Deixe vazio para usar o valor padrão: "Nome Completo" <email>'
                    />

                    {showEditor ? (
                      <TemplateEditor
                        editorData={campaign.body.editor || {}}
                        setShowEditor={setShowEditor}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        reloadTemplate={reloadCampaign}
                        readOnly={readOnly}
                      />
                    ) : null}
                    {!continuous ? (
                      <div>
                        <Field
                          // className={classes.destSel}
                          variant="outlined"
                          name="type"
                          component={SelectFMU}
                          inputProps={{readOnly}}
                        >
                          <MenuItem value={'marketing'}>Marketing</MenuItem>
                          <MenuItem value={'transactional'}>
                            Transacional
                          </MenuItem>
                        </Field>

                        <Typography
                          component="h3"
                          variant="subtitle1"
                          color="primary"
                          gutterBottom
                        >
                          Destinatários
                        </Typography>
                        <Select
                          className={classes.destSel}
                          variant="outlined"
                          value={selDest}
                          onChange={e => {
                            setSelDest(e.target.value);
                          }}
                          inputProps={{readOnly}}
                        >
                          <MenuItem value={'none'}>Escolha um tipo</MenuItem>
                          <MenuItem value={'paste'}>Colar</MenuItem>
                          <MenuItem value={'filter'}>Filtrar</MenuItem>
                          <MenuItem value={'all'}>Toda a base</MenuItem>
                        </Select>

                        {selDest === 'paste' ? (
                          <Field
                            className={
                              mainClasses.field + ' ' + classes.pasteArea
                            }
                            name="destList"
                            component={TextField}
                            label="Lista"
                            multiline
                            rowsMax={10}
                            InputProps={{readOnly: readOnly}}
                          />
                        ) : null}

                        {selDest === 'filter' ? (
                          <Conditional
                            frame={false}
                            projectId={projectId}
                            cmpVal="mailSubscriber"
                            condition={{filter: values.destFilter}}
                            onChange={cond => {
                              setFieldValue('destFilter', cond.filter);
                            }}
                          />
                        ) : null}

                        {!readOnly && campaignId ? (
                          <div style={{marginBottom: '20px'}}>
                            <Button
                              type="button"
                              className={classes.btn}
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || readOnly}
                              onClick={async () => {
                                let r = await submit({
                                  editing: true,
                                  ...values,
                                });
                                if (r.error) {
                                  alert(r.error);
                                  return;
                                }

                                r = await api.recipientsCampaign(campaignId);
                                if (r.error) {
                                  alert(r.error);
                                  return;
                                }

                                setRcpCounts(r.response);
                              }}
                            >
                              Contar
                            </Button>

                            {rcpCounts ? (
                              <ul>
                                <li>
                                  <b>válidos: {rcpCounts.valid}</b>
                                </li>
                                {rcpCounts.uniqueEmails ? (
                                  <li>
                                    emails únicos: {rcpCounts.uniqueEmails}
                                  </li>
                                ) : null}
                                {rcpCounts.notFound ? (
                                  <li>não encontrados: {rcpCounts.notFound}</li>
                                ) : null}
                                {rcpCounts.notMatched ? (
                                  <li>
                                    excluídos pelo filtro:{' '}
                                    {rcpCounts.notMatched}
                                  </li>
                                ) : null}
                                {rcpCounts.notSubscribed ? (
                                  <li>
                                    não inscritos: {rcpCounts.notSubscribed}
                                  </li>
                                ) : null}
                              </ul>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {/* 
                
                Test
                */}
                    {!readOnly ? (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendTest}
                            onChange={() => setSendTest(!sendTest)}
                          />
                        }
                        label="Enviar e-mail teste ao salvar?"
                      />
                    ) : null}
                    {sendTest && !readOnly ? (
                      <Field
                        className={mainClasses.field}
                        name="sendTestTo"
                        component={TextField}
                        label="Enviar teste para"
                      />
                    ) : null}
                    {/* 
                
                Schedule
                */}
                    {!readOnly && !continuous ? (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={scheduled}
                            onChange={() =>
                              setScheduled(scheduled ? false : dayjs())
                            }
                          />
                        }
                        label="Agendar envio?"
                      />
                    ) : null}
                    {scheduled ? (
                      <DateTimePicker
                        renderInput={props => (
                          <Field
                            className={mainClasses.field}
                            {...props}
                            name="scheduled"
                            component={TextField}
                            helperText=""
                          />
                        )}
                        label="Agendar para"
                        value={scheduled}
                        ampm={false}
                        onChange={setScheduled}
                        inputFormat="DD/MM/YYYY HH:mm"
                        inputProps={{readOnly}}
                      />
                    ) : null}

                    {!campaign ? (
                      <Field
                        className={mainClasses.field}
                        name="templateId"
                        label="Template"
                        component={TextField}
                        select
                      >
                        {Object.values(templates).map(template => (
                          <MenuItem value={template.id} key={template.id}>
                            {template.name}
                          </MenuItem>
                        ))}
                      </Field>
                    ) : null}
                  </div>
                ) : null}

                <ButtonGroup
                  size="large"
                  variant="contained"
                  color="primary"
                  aria-label="large contained primary button group"
                >
                  {/* 
                  
                  Template
                  */}
                  {campaign ? (
                    <Button
                      type="button"
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      onClick={() => setShowEditor(true)}
                    >
                      Template
                    </Button>
                  ) : null}
                  {/* 
                
                  Save
                  */}
                  {!readOnly ? (
                    <Button
                      type="submit"
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {submitLabel}
                    </Button>
                  ) : null}
                  {/* 
                
                  Start
                  */}
                  {!readOnly && !continuous && campaignId ? (
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.btn}
                      color="secondary"
                      disabled={isSubmitting || readOnly || selDest === 'none'}
                      onClick={async () => {
                        if (
                          !window.confirm(
                            scheduled
                              ? 'Salvar campanha e agendar disparo de emails?'
                              : 'Salvar campanha e iniciar disparo de emails?'
                          )
                        ) {
                          return;
                        }

                        let r = await submit({editing: true, ...values});
                        if (r.error) {
                          alert(r.error);
                          reloadCampaign();
                          return;
                        }

                        r = await api.startCampaign(campaignId);
                        if (r.error) {
                          alert(r.error);
                        }

                        reloadCampaign();
                      }}
                    >
                      {scheduled ? 'Agendar' : 'Iniciar'}
                    </Button>
                  ) : null}
                  {/* 

                Stop
                */}
                  {campaign && readOnly && !continuous ? (
                    <Button
                      type="button"
                      className={classes.btn}
                      variant="contained"
                      color="secondary"
                      disabled={
                        isSubmitting ||
                        !readOnly ||
                        campaign.status === 'stopping'
                      }
                      onClick={async () => {
                        if (!window.confirm(stopLabel + ' campanha?')) {
                          return;
                        }
                        await api.stopCampaign(campaignId);
                        reloadCampaign();
                      }}
                    >
                      {stopLabel}
                    </Button>
                  ) : null}
                </ButtonGroup>
              </Form>
            )}
          </Formik>

          {/* Preview link */}
          {campaignId ? (
            <Link
              style={{marginTop: '10px'}}
              href={api.templatePreview(campaignId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              previsualização
            </Link>
          ) : null}
        </Paper>
      </Grid>
    </Frame>
  );
};

CampaignForm.propTypes = {
  campaignId: PropTypes.string,
  channel: PropTypes.object,
};

export default CampaignForm;
