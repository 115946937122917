import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {CheckboxWithLabel} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';

const AggForm = ({isLoading, fields, fetchTable}) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  let initialValues = {};
  for (let f of fields) {
    initialValues[f.name] = false;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, {setSubmitting}) => {
        let ak = [];
        for (let [k, v] of Object.entries(values)) {
          if (v) {
            ak.push(k);
          }
        }
        fetchTable(ak);
        setSubmitting(false);
      }}
    >
      {({isSubmitting}) => (
        <Form>
          {fields.map(f => (
            <Field
              key={f.name}
              name={f.name}
              Label={{label: f.label}}
              component={CheckboxWithLabel}
            />
          ))}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Agrupar
          </Button>
        </Form>
      )}
    </Formik>
  );
};

AggForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fields: PropTypes.array,
  fetchTable: PropTypes.func.isRequired,
};

export default AggForm;
