import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Table from 'components/misc/Table';
import AggForm from 'components/channel/AggForm';
import Layout from 'components/misc/Layout';
import ChartTimeLine from 'components/channel/ChartTimeLine';
import {api} from 'services';

dayjs.extend(relativeTime);

const createIndex = 0;
const modifIndex = 1;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const loading = {
  title: 'carregando...',
  isLoading: true,
  columns: [],
  data: [],
};

// Convert response entries from arrays of values to objects with key (header)
// and value.
function recordsFrom(resp) {
  if (!resp.entries) {
    return [];
  }

  const rows = [];
  for (let ent of resp.entries) {
    const row = {};

    for (let ci = 0; ci < ent.length; ci++) {
      const label = resp.header[ci];
      let value = ent[ci];

      if (ci === createIndex || ci === modifIndex) {
        let d = dayjs(value + 'Z');
        value = d.format();
        if (ci === createIndex) {
          row.createdAt = d;
        }
        if (ci === modifIndex) {
          row.ago = d.fromNow();
          row.date = d.format('LLL');
        }
      }

      row[label] = value;
    }

    rows.push(row);
  }

  return rows;
}

const cellStyle = {
  whiteSpace: 'nowrap',
};

async function fetchTable(setTable, channel, ak) {
  setTable(loading);
  let r = (await api.aggregate({channels: [channel.id], ak})).response;

  const columns = r.header.map(n => ({title: n, field: n, cellStyle}));

  let opts = {hidden: true, export: true};
  Object.assign(columns[createIndex], opts);
  Object.assign(columns[modifIndex], opts);

  columns.unshift({
    title: 'Data/Hora',
    field: 'ago',
    cellStyle,
    export: false,
    defaultSort: 'desc',
    customSort: (a, b) => a.createdAt - b.createdAt,
    render: rowData => (
      <Tooltip title={rowData.date}>
        <span>{rowData.ago}</span>
      </Tooltip>
    ),
  });

  setTable({
    title: r.title,
    columns,
    data: recordsFrom(r),
  });
}

const ChannelView = ({channelId}) => {
  const classes = useStyles();

  const channel = useSelector(state => state.channels.items[channelId]);
  const group = useSelector(state =>
    channel ? state.groups.items[channel.group] : null
  );
  const fields = useSelector(state =>
    group ? group.fields.map(fid => state.fields.items[fid]) : null
  );
  const isLoading = useSelector(state =>
    Boolean(
      (channelId && (!channel || !group || !fields)) || state.channels.isLoading
    )
  );

  const [table, setTable] = useState(loading);

  useEffect(() => {
    if (!isLoading) {
      fetchTable(setTable, channel, []);
    }
  }, [channel, isLoading]);

  return (
    <Layout>
      <Grid container spacing={3}>
        {table.data.length > 0 ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ChartTimeLine dateLabel={'createdAt'} countRecords={true} data={table.data} />
            </Paper>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AggForm
              isLoading={isLoading}
              fields={fields}
              fetchTable={fetchTable.bind(this, setTable, channel)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Table
            {...table}
            options={{
              exportButton: true,
              exportAllData: true,
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

ChannelView.propTypes = {
  channelId: PropTypes.string,
};

export default ChannelView;
