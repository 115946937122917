import {combineReducers} from 'redux';
import createSagaMiddleware, {END} from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import {createReduxHistoryContext, reachify} from 'redux-first-history';
import {createBrowserHistory} from 'history';
import modelsReducer from './reducers';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware({sagaMonitor});

const rootReducer = combineReducers({
  router: routerReducer,
  ...modelsReducer,
});

var createStore =
  process.env.NODE_ENV === 'production'
    ? require('./config.prod.js').default
    : require('./config.dev.js').default;

const store = createStore(
  rootReducer,
  sagaMiddleware,
  routerMiddleware,
  routerReducer
);

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);

export const history = reachify(createReduxHistory(store));
export default store;

window.app_router = history;

// HACK to avoid https://github.com/reach/router/issues/408
const buggedNavigate = history.navigate;
history.navigate = path => {
  if (path === '..') {
    path = window.location.pathname.split('/').slice(0, -2).join('/');
  }
  buggedNavigate(path);
};
