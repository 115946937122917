import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Menu from 'components/misc/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  main: {
    width: '100%',
    minWidth: 0,
  },
}));

const Dashboard = ({children, projectId}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Menu projectId={projectId} />
      <div className={classes.main}>{children}</div>
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default Dashboard;
