export default [
  {
    tagName: 'mjml',
    type: 'mjml',
    content: '',
    components: [
      {
        tagName: 'mj-head',
        type: 'mj-head',
        content: '',
        classes: [
          {
            name: 'hidden',
            label: 'hidden',
            type: 1,
            active: true,
            private: false,
            protected: false,
          },
        ],
        components: [
          {
            tagName: 'mj-attributes',
            content: '',
            components: [
              {
                tagName: 'mj-text',
                type: 'mj-text',
                content: '',
                attributes: {
                  'line-height': '24px',
                  'font-size': '16px',
                  padding: '10px 25px 10px 25px',
                  align: 'left',
                  id: 'itaf',
                },
                open: false,
              },
            ],
            open: 0,
          },
          {
            tagName: 'mj-preview',
            type: 'text',
            content: '{{.PreHeader}}',
            attributes: {id: 'i014'},
            open: false,
          },
        ],
        open: 0,
      },
      {
        tagName: 'mj-body',
        type: 'mj-body',
        content: '',
        style: {width: '600px', 'background-color': '#f8f8f8'},
        attributes: {width: '600px', 'background-color': '#f8f8f8', id: 'ih9j'},
        components: [
          {
            tagName: 'mj-section',
            type: 'mj-section',
            content: '',
            style: {
              padding: '10px 0 10px 0',
              'vertical-align': 'top',
              'text-align': 'center',
            },
            attributes: {
              padding: '10px 0 10px 0',
              'vertical-align': 'top',
              'text-align': 'center',
              id: 'ilji',
            },
            components: [
              {
                tagName: 'mj-column',
                type: 'mj-column',
                content: '',
                style: {'background-color': '#ffffff'},
                attributes: {'background-color': '#ffffff', id: 'iodx'},
                open: 0,
                components: [
                  {
                    tagName: 'mj-text',
                    type: 'mj-text',
                    content: '',
                    style: {
                      padding: '10px 25px 10px 25px',
                      'font-size': '16px',
                      'line-height': '24px',
                      align: 'left',
                    },
                    attributes: {
                      padding: '10px 25px 10px 25px',
                      'font-size': '16px',
                      'line-height': '24px',
                      align: 'left',
                      id: 'ipdv',
                    },
                    components: [
                      {
                        tagName: '',
                        type: 'textnode',
                        removable: false,
                        draggable: false,
                        highlightable: 0,
                        copyable: false,
                        content: 'Clique duplo para inserir texto aqui.',
                        _innertext: false,
                      },
                    ],
                  },
                ],
              },
            ],
            open: 0,
          },
        ],
        open: 0,
      },
    ],
    open: 0,
  },
];
