import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import Title from 'components/misc/Title';

dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.locale('pt-br');

const useStyles = makeStyles(() => ({
  grid: {
    display: 'flex',
    marginBottom: '20px',
  },
  title: {
    paddingRight: '10px',
    paddingTop: '6px',
  },
  select: {
    fontSize: '15pt',
  },
}));

const aggregators = {
  hours: {
    name: 'hora',
    aggFmt: 'YYYY/MM/DD-HH',
    labelFmt: 'HH[h] L',
  },
  days: {
    name: 'dia',
    aggFmt: 'L',
    labelFmt: 'L',
  },
  weeks: {
    name: 'semana',
    aggFmt: 'YYYY/ww',
    labelFmt: 'L',
  },
  months: {
    name: 'mês',
    aggFmt: 'MM/YYYY',
    labelFmt: 'MM/YYYY',
  },
};

// A time chart.
// dateLabel: key, in the records, with the date
// data: records, a list of objects, like: [{date: '2020-01-01', value: 2}]
// countRecords:
// if true, count records by date, display one line with count
// if false, assume records contain sums of events by date, and display one line for each type of events
function Chart({dateLabel, data, countRecords}) {
  const [aggregator, setAgg] = useState('days');
  const classes = useStyles();

  // aggregate events per time period
  const {aggFmt, labelFmt} = aggregators[aggregator];
  const sums = {};
  var valuesNames = {}
  const defKey = 'main'
  for (let evt of data) {
    const ak = evt[dateLabel].format(aggFmt);

    let ss = sums[ak]
    if (!ss) {
      ss = {}
      sums[ak] = ss
    }

    if (countRecords) {
      ss[defKey] = (ss[defKey] || 0) + 1;
    } else {
      for (let k of Object.keys(evt)) {
        if (k !== dateLabel) {
          valuesNames[k] = true
          ss[k] = (ss[k] || 0) + evt[k]
        }
      }
    }
  }

  valuesNames = Object.keys(valuesNames)
  if (countRecords) {
    valuesNames = [defKey]
  }

  // convert data to chart format
  const dates = [];
  const values = {};
  for (let n of valuesNames) {
    values[n] = []
  }
  const startDate = data[0][dateLabel].startOf(aggregator);
  const endDate = dayjs().endOf(aggregator);
  
  for (let d = startDate; d <= endDate; d = d.add(1, aggregator)) {
    dates.push(d.format(labelFmt));
    let s = sums[d.format(aggFmt)]
    for (let n of valuesNames) {
      values[n].push(s && s[n] ? s[n] : 0)
    }
  }

  let series = [];

  for (let n of valuesNames) {
    series.push({
      name: n,
      type: 'line',
      data: values[n],
      symbol: 'none',
      sampling: 'average',
      itemStyle: countRecords ? {color: '#5856c3'} : undefined,
    })
  }

  return (
    <Grid container spacing={0}>
      <Grid className={classes.grid} item xs={12}>
        <Title className={classes.title}>Eventos por</Title>
        <Select
          className={classes.select}
          labelId="agg-select-label"
          id="agg-select"
          value={aggregator}
          onChange={e => setAgg(e.target.value)}
        >
          {Object.entries(aggregators).map(([i, g]) => (
            <MenuItem value={i} key={i}>
              {g.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <ReactEcharts
          option={{
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              },
            },
            legend: countRecords ? undefined : {},
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: dates,
            },
            yAxis: {
              type: 'value',
            },
            series,
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: 'none',
                  title: {
                    zoom: 'zoom',
                    back: 'voltar',
                  },
                },
              },
            },
            dataZoom: [
              {
                start: 0,
                end: 100,
              },
            ],
          }}
          opts={{renderer: 'svg'}}
        />
      </Grid>
    </Grid>
  );
}

Chart.propTypes = {
  dateLabel: PropTypes.string,
  data: PropTypes.array,
};

export default Chart;
