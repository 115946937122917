import {
  delay,
  take,
  race,
  put,
  call,
  cancel,
  fork,
  all,
  takeEvery,
} from 'redux-saga/effects';
import {api, token, fmtAction, history} from 'services';

function fmtPut(type, payload) {
  return put(fmtAction(type, payload));
}

function* fetchModel(modelName, fn, action) {
  const payload = action ? action.payload : {};
  yield fmtPut(`${modelName}/loading`);
  const {response, error} = yield call(fn, payload);
  if (error) {
    yield fmtPut(`${modelName}/failed`, error);
  } else {
    // put items in each model referenced in the response
    for (const [entryModelName, items] of Object.entries(response)) {
      yield fmtPut(`${entryModelName}/putItems`, items);
    }
    yield fmtPut(`${modelName}/endLoading`);
  }
}

function* autoRefreshToken() {
  while (true) {
    let {response} = yield call(api.renewToken);
    yield call(token.setToken, response.token);

    let {exp, iat} = token.getParsedToken();
    yield delay(Math.max(exp - iat - 30, 5) * 1000);
  }
}

/** ***************************************************************************/
/** **************************** WATCHERS *************************************/
/** ***************************************************************************/

function* watchAuth() {
  var userData = token.processTokenInHash();
  if (!userData) {
    userData = token.getParsedToken();
  }
  // If neither hash nor localStorage has a token, this website execution will
  // never be authenticated, since current oauth login implementation restarts
  // the page with token in hash, forcing another website execution.
  if (!userData) {
    // isLoggedIn starts undefined, set it to false
    yield fmtPut('user/clear');
    yield cancel();
  }

  // Restore path from before login redirect
  const prev = localStorage.getItem('restorePath');
  if (prev) {
    localStorage.removeItem('restorePath');
    history.navigate(prev);
  }

  yield fmtPut('user/set', userData);
  yield fork(fetchModel, 'constants', api.getConstants);
  // fetch user owned data
  yield fork(fetchModel, 'channels', api.getAll);

  // stop autoRefreshToken when logout is clicked or the token is invalid
  yield race([
    call(autoRefreshToken),
    take('logoutClicked'),
    take('invalidToken'),
  ]);
  yield fmtPut('user/clear');
  yield call(token.removeToken);
}

function* setItem(modelName, {payload}) {
  yield fmtPut(`${modelName}/setItem`, payload);
}

export default function* root() {
  yield all([
    fork(watchAuth),
    takeEvery('setPipeline', setItem, 'pipelines'),
    // For now we are resolving all "outdated" events with the same "getAll" method.
    takeEvery('projectsOutdated', fetchModel, 'projects', api.getAll),
    takeEvery('channelsOutdated', fetchModel, 'channels', api.getAll),
    takeEvery('templatesOutdated', fetchModel, 'templates', api.getAll),
    takeEvery('campaignsOutdated', fetchModel, 'campaigns', api.getAll),
    takeEvery('pipelinesOutdated', fetchModel, 'pipelines', api.getAll),
  ]);
}
