import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import Edit from '@material-ui/icons/Edit';
import Duplicate from '@material-ui/icons/FileCopy';
import DotsIcon from '@material-ui/icons/MoreHoriz';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Menu from '@material-ui/core/Menu';
import Delete from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from '@reach/router';
import dayjs from 'dayjs';

import {api} from 'services';
import Table from 'components/misc/Table';
import {timestampFromObjectId} from 'utils';
import Layout from 'components/misc/Layout';

const TemplatesList = ({projectId}) => {
  const dispatch = useDispatch();

  const templates = useSelector(
    state =>
      Object.values(state.templates.items).filter(
        t => t.projectId === projectId
      ),
    shallowEqual
  );
  const isLoading = useSelector(state => state.templates.isLoading);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuRow, setMenuRow] = React.useState(null);

  function handleClick(event, row) {
    setAnchorEl(event.currentTarget.parentElement);
    setMenuRow(row);
  }

  function handleClose(cb) {
    return function () {
      setAnchorEl(null);
      setMenuRow(null);
      if (cb) {
        cb();
      }
    };
  }

  return (
    <Layout>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        <MenuItem>
          <Link to={`../templates/${menuRow ? menuRow.id : ''}/edit`}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Editar Template" />
          </Link>
        </MenuItem>

        <MenuItem
          onClick={handleClose(async () => {
            await api.duplicateTemplate(menuRow.id);
            dispatch({type: 'templatesOutdated'});
          })}
        >
          <ListItemIcon>
            <Duplicate />
          </ListItemIcon>
          <ListItemText primary="Duplicar Template" />
        </MenuItem>

        <MenuItem
          onClick={handleClose(async () => {
            if (window.confirm(`Deletar template ${menuRow.name}?`)) {
              await api.deleteTemplate(menuRow.id);
              dispatch({type: 'templatesOutdated'});
            }
          })}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Deletar Template" />
        </MenuItem>
      </Menu>
      <Table
        isLoading={isLoading}
        title="Templates"
        columns={[
          {title: 'Nome', field: 'name'},
          {title: 'Nome_', field: 'name_', hidden: true, searchable: true},
          {
            title: 'Data',
            field: 'date',
            defaultSort: 'desc',
            customSort: (a, b) => a.createdAt - b.createdAt,
          },
        ]}
        data={Object.values(templates).map(c => {
          const createdAt = timestampFromObjectId(c.id);
          return {
            name: c.name,
            name_: c.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            date: dayjs(createdAt).format('DD/MM/YYYY'),
            createdAt,
            id: c.id,
          };
        })}
        actions={[
          {
            icon: () => <NoteAdd />,
            tooltip: 'Criar Template',
            isFreeAction: true,
            href: () => `new`,
            onClick: () => {},
          },
          {
            icon: () => <DotsIcon />,
            tooltip: 'Ações',
            onClick: handleClick,
          },
        ]}
      />
    </Layout>
  );
};

TemplatesList.propTypes = {
  projectId: PropTypes.string,
};

export default TemplatesList;
