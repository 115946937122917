import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import Edit from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {api, history} from 'services';
import Table from 'components/misc/Table';

const Fields = ({groupId}) => {
  const dispatch = useDispatch();
  const group = useSelector(state => state.groups.items[groupId]) || {};
  const fields = useSelector(state => {
    const fields = group.id
      ? group.fields.map(f => state.fields.items[f]).filter(Boolean)
      : [];
    for (let i = 0; i < fields.length; i++) {
      fields[i].order = i;
    }
    return fields;
  }, shallowEqual);
  const isLoading = useSelector(state => state.channels.isLoading);

  // Move a field up (pos=-1) or down (pos=1)
  async function moveField(field, pos) {
    let ids = fields.map(f => f.id);
    let id = ids[field.order];
    ids[field.order] = ids[field.order + pos];
    ids[field.order + pos] = id;
    await api.reorderFields({groupId: group.id, fields: ids});
    dispatch({type: 'channelsOutdated'});
  }

  return (
    <Table
      isLoading={isLoading}
      title="Campos"
      columns={[
        {title: 'Nome', field: 'name'},
        {title: 'Rótulo', field: 'label'},
        {title: 'Tipo', field: 'type'},
        {title: 'Validação', field: 'validation'},
        {title: 'Público', field: 'public'},
      ]}
      data={fields.map(f => ({
        id: f.id,
        name: f.name,
        label: f.label,
        type: f.type,
        order: f.order,
        validation: f.validation ? f.validation.join(', ') : '',
        public: f.public ? '✔' : '✖',
      }))}
      editable={{
        onRowDelete: async f => {
          await api.deleteField({groupId: group.id, fieldId: f.id});
          dispatch({type: 'channelsOutdated'});
        },
      }}
      actions={[
        row => ({
          icon: () => <KeyboardArrowUpIcon />,
          tooltip: 'Move campo para cima',
          onClick: async () => moveField(row, -1),
          disabled: row.order === 0,
        }),
        row => ({
          icon: () => <KeyboardArrowDownIcon />,
          tooltip: 'Move campo para baixo',
          onClick: async () => moveField(row, 1),
          disabled: row.order === fields.length - 1,
        }),
        {
          icon: () => <Edit />,
          tooltip: 'Editar Campo',
          onClick: (event, row) =>
            history.navigate(`${group.id}/fields/${row.id}`),
        },
      ]}
    />
  );
};

Fields.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default Fields;
