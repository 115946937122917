import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {Router, LocationProvider, Redirect} from '@reach/router';

import ChannelEdit from 'components/channel/ChannelEdit';
import Channels from 'components/channel/ChannelsView';
import ChannelView from 'components/channel/ChannelView';
import Dashboard from 'components/misc/Dashboard';
import Field from 'components/channel/field/FieldView';
import Group from 'components/channel/field/FieldsView';
import MailSubscribers from 'components/mailsub/MailSubscribersList';
import MailSubscriber from 'components/mailsub/MailSubscriberView';
import LoginGuard from 'components/login/LoginGuard';
import Templates from 'components/template/TemplatesList';
import Template from 'components/template/TemplateForm';
import Projects from 'components/project/ProjectsList';
import ProjView from 'components/project/ProjectView';
import ProjEdit from 'components/project/ProjectForm';
import MailCampaigns from 'components/mailcamp/MailCampaignsList';
import MailCampEvts from 'components/mailcamp/MailCampEvts';
import Pipeline from 'components/automation/Pipeline';
import CampaignForm from 'components/mailcamp/CampaignForm';

// Based on: https://github.com/reach/router/issues/242
export const ScrollToTop = ({children, location}) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

const Root = ({store, history}) => (
  <Provider store={store}>
    <LocationProvider history={history}>
      <LoginGuard>
        <Router primary={false}>
          <ScrollToTop path="/">
            <Redirect from="/" to="/projects" noThrow={true} />
            <Projects path="/projects" />
            <ProjEdit path="/projects/new" />
            <Dashboard path="/projects/:projectId" primary={false}>
            <ProjView path="/" />
              <ProjEdit path="/edit" />
              <Channels path="/channels" />
              <ChannelView path="/channels/:channelId" />
              <ChannelEdit path="/channels/:channelId/edit" />
              <Pipeline path="/channels/:channelId/pipeline" />
              <MailCampaigns path="/mail_campaigns" />
              <CampaignForm path="/mail_campaigns/new" type="marketing" />
              <CampaignForm path="/mail_campaigns/:campaignId" />
              <MailCampEvts path="/mail_campaigns/:campaignId/:event" />
              <Group path="/groups/:groupId" />
              <Field path="/groups/:groupId/fields/:fieldId" />
              <Templates path="/templates" />
              <Template path="/templates/new" />
              <Template path="/templates/:templateId/edit" />
              <MailSubscribers path="/mail_subscribers" />
              <MailSubscriber path="/mail_subscribers/:mailSubscriberId" />
            </Dashboard>
          </ScrollToTop>
        </Router>
      </LoginGuard>
      {process.env.NODE_ENV === 'development'
        ? React.createElement(require('components/misc/DevTools').default)
        : null}
    </LocationProvider>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
