import {merge} from 'lodash';
import {captureException} from '@sentry/browser';
import {getRawToken} from './token';
import store from 'store';
import {fmtAction} from 'services';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// export const baseUrl = "https://api.evag.io";

export default async function request(url, method, body) {
  var response, error;

  // base JSON request config
  const config = {
    method: method ? method : 'GET',
    body: body ? JSON.stringify(body) : null,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  // base api authed request
  const apiConfig = {
    withCredentials: true,
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + getRawToken(),
    },
  };

  let fullUrl = url;
  let fullConfig = config;
  // check if is a request to the api or not
  if (!fullUrl.startsWith('http')) {
    fullUrl = `${baseUrl}/${url}`;
    fullConfig = merge(config, apiConfig);
  }

  try {
    response = await fetch(fullUrl, fullConfig);
  } catch (err) {
    captureException(err);
    error = 'Error fetching: ' + url;
    console.log(error);
  }

  if (response && !response.ok) {
    if (response.status === 401) {
      store.dispatch(fmtAction('invalidToken'));
    }

    error = await response.text();
    console.error(
      'Response not ok:\n',
      response.status,
      response.statusText,
      '\n',
      error
    );
  } else {
    response = await response.json();
  }

  return {
    response,
    error,
  };
}
