import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Layout from 'components/misc/Layout';
import ChartTimeLine from 'components/channel/ChartTimeLine';
import {api} from 'services';

dayjs.extend(relativeTime);

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const loading = {
  title: 'carregando...',
  isLoading: true,
  columns: [],
  data: [],
};


async function fetchTable(setData, projectId) {
  setData(loading);
  let r = (await api.mailEvtPerDate({projectId})).response;

  let data = r.data

  for (let d of data) {
    d.date = dayjs(d.date)
    d.total = (d.delivery || 0) + (d.bounce || 0)
  }

  setData(data);
}

const ProjectView = ({projectId}) => {
  const classes = useStyles();

  const [data, setData] = useState(loading);

  useEffect(() => {
    fetchTable(setData, projectId, []);
  }, [projectId]);

  let sents = 0
  if (data.length) {
    let startMonth = dayjs(dayjs().format('YYYY-MM'))
    for (let d of data) {
      if (d.date >= startMonth) {
        sents += (d.delivery || 0) + (d.bounce || 0)
      }
    }
  }

  return (
    <Layout>
      {data.length > 0 ? (
      <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ChartTimeLine dateLabel={'date'} data={data} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              Envios no mês atual: {sents}
            </Paper>
          </Grid>
      </Grid>
      ) : null}
    </Layout>
  );
};

ProjectView.propTypes = {
  projectId: PropTypes.string,
};

export default ProjectView;
