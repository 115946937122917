import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(4),
  },
}));

const Layout = ({children}) => {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default Layout;
