import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {api} from 'services';
import Table from 'components/misc/Table';
import Layout from 'components/misc/Layout';

const MailCampEvts = ({campaignId, event}) => {
  const [state, setState] = useState({data: [], isLoading: true});
  useEffect(() => {
    (async () => {
      let r = await api.mailCampaignEvts({campaignId, event});
      setState({
        data: r.response.emails.map(e => ({email: e})),
        isLoading: false,
      });
    })();
  }, [campaignId, event]);

  return (
    <Layout>
      <Table
        isLoading={state.isLoading}
        title={event}
        columns={[{title: 'E-mail', field: 'email'}]}
        data={state.data}
      />
    </Layout>
  );
};

MailCampEvts.propTypes = {
  campaignId: PropTypes.string,
  event: PropTypes.string,
};

export default MailCampEvts;
