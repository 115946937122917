// A container+component is used here to allow recursion.
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DotsIcon from '@material-ui/icons/MoreHoriz';
import TrueIcon from '@material-ui/icons/Check';
import FalseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  content: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
  },
  step: {
    width: '150px',
    height: '150px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  parent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  children: {
    display: 'flex',
    justifyContent: 'center',
  },
  conditionChildren: {
    borderTop: '1px solid #ccc',
    borderRadius: '8px',
  },
  center: {
    textAlign: 'center',
  },
  truePath: {
    '&:last-child': {
      marginRight: '170px',
    },
  },
  falsePath: {
    '&:first-child': {
      marginLeft: '170px',
    },
  },
}));

const units = [
  {mult: 86400, label: 'dias'},
  {mult: 3600, label: 'horas'},
  {mult: 60, label: 'minutos'},
  {mult: 1, label: 'segundos'},
];

const Delay = ({delay}) => {
  const classes = useStyles();

  let initVal;
  let initUnit;
  for (let u of units) {
    if (delay.duration % u.mult === 0) {
      initUnit = u.mult;
      initVal = delay.duration / u.mult;
      break;
    }
  }

  const [unit, setUnit] = useState(initUnit);
  const [val, setVal] = useState(initVal);

  return (
    <div>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        align="center"
      >
        <b>Aguardar:</b>
      </Typography>
      <FormControl>
        <TextField
          label=""
          type="number"
          InputProps={{
            classes: {input: classes.center},
          }}
          value={val}
          onChange={e => {
            let newVal = e.target.value;
            delay.duration = newVal * unit;
            setVal(newVal);
          }}
        />
        <Select
          className={classes.center}
          value={unit}
          onChange={e => {
            let newUnit = e.target.value;
            delay.duration = val * newUnit;
            setUnit(newUnit);
          }}
        >
          {units.map((u, i) => (
            <MenuItem key={i} value={u.mult}>
              {val === 1 ? u.label.slice(0, -1) : u.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

function content(ctx, parent, step, campaign) {
  if (step.delay) return <Delay delay={step.delay} />;

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
      align="center"
    >
      {!parent ? (
        <b>Quando o formulário for preenchido</b>
      ) : campaign ? (
        <span>
          <b>Enviar campanha:</b> {campaign.subject}
        </span>
      ) : step.condition ? (
        <b>Condição</b>
      ) : step.action.mcId ? (
        step.action.mcId
      ) : (
        '(não salva)'
      )}
    </Typography>
  );
}

const Step = ({ctx, step, parent, getParentArray, ...props}) => {
  const classes = useStyles();

  const campaign = useSelector(
    state =>
      step.action && step.action.mcId
        ? state.campaigns.items[step.action.mcId]
        : null,
    shallowEqual
  );

  return (
    <div {...props}>
      <div className={classes.parent}>
        <Card
          className={classes.step}
          onClick={() => ctx.setSelectedId(step.id)}
          raised={ctx.selectedStep === step}
        >
          <CardHeader
            className={classes.header}
            action={
              <IconButton
                size="small"
                aria-label="settings"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={event =>
                  ctx.setMenuTarget({
                    elem: event.currentTarget.parentElement,
                    getParentArray,
                  })
                }
              >
                <DotsIcon />
              </IconButton>
            }
          />
          <CardContent className={classes.content}>
            {content(ctx, parent, step, campaign)}
          </CardContent>
          {step.condition ? (
            <Box display="flex" justifyContent="space-between">
              <IconButton
                size="small"
                aria-label="settings"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={event =>
                  ctx.setMenuTarget({
                    elem: event.currentTarget,
                    getArray: step => step.condition.true,
                  })
                }
              >
                <TrueIcon />
              </IconButton>

              <IconButton
                size="small"
                aria-label="settings"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={event =>
                  ctx.setMenuTarget({
                    elem: event.currentTarget,
                    getArray: step => step.condition.false,
                  })
                }
              >
                <FalseIcon />
              </IconButton>
            </Box>
          ) : null}
        </Card>
      </div>
      <div
        className={
          classes.children +
          (step.condition ? ' ' + classes.conditionChildren : '')
        }
      >
        {step.tree ? (
          <Step step={step.tree} parent={step} ctx={ctx} />
        ) : step.condition ? (
          <>
            {step.condition.true.map((child, i) => (
              <Step
                className={classes.truePath}
                key={i}
                step={child}
                parent={step}
                ctx={ctx}
                getParentArray={step => step.condition.true}
              />
            ))}
            {step.condition.false.map((child, i) => (
              <Step
                className={classes.falsePath}
                key={i}
                step={child}
                parent={step}
                ctx={ctx}
                getParentArray={step => step.condition.false}
              />
            ))}
          </>
        ) : step.children ? (
          step.children.map((child, i) => (
            <Step key={i} step={child} parent={step} ctx={ctx} />
          ))
        ) : null}
      </div>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.object,
  parent: PropTypes.object,
  getParentArray: PropTypes.func,
};

export default Step;
