import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ChannelForm from 'components/channel/ChannelForm';
import Layout from 'components/misc/Layout';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const ChannelEdit = ({channelId}) => {
  const classes = useStyles();
  return (
    <Layout>
      <Paper className={classes.paper}>
        <ChannelForm channelId={channelId} />
      </Paper>
    </Layout>
  );
};

ChannelEdit.propTypes = {
  channelId: PropTypes.string,
};

export default ChannelEdit;
