import {api} from 'services';

// Plugin to add a video block to the editor.
export default editor => {
  let compId = 'mj-video';
  editor.DomComponents.addType(compId, {
    isComponent: el => {
      return el.tagName === compId.toUpperCase();
    },
    extend: 'mj-image',
    model: {
      attributes: {src: ''},
      defaults: {
        name: 'Video',
        traits: ['src', 'href', 'rel', 'alt', 'title'],
      },

      getMjmlAttributes() {
        let attr = this.get('attributes') || {};
        delete attr.style;
        return attr;
      },

      toHTML() {
        let model = this;
        let tagName = 'mj-image';
        let attr = model.getMjmlAttributes();
        let strAttr = '';

        for (let prop in attr) {
          let val = attr[prop];
          strAttr +=
            typeof val !== undefined && val !== ''
              ? ' ' + prop + '="' + val + '"'
              : '';
        }
        return `<${tagName}${strAttr}></${tagName}>`;
      },
    },
    view: {
      events: {
        click: function () {
          let url = this.model.getAttributes().href || '';
          url = prompt('Entre com o link para o vídeo:', url) || url;
          this.model.setAttributes({
            src: api.thumbnailPrevURL(url),
            href: url,
          });
        },
      },

      getInnerMjmlTemplate() {
        const model = this.model;
        let tagName = 'mj-image';
        let attr = model.getMjmlAttributes();
        let strAttr = '';

        for (let prop in attr) {
          let val = attr[prop];
          strAttr +=
            typeof val !== undefined && val !== ''
              ? ' ' + prop + '="' + val + '"'
              : '';
        }

        return {
          start: `<${tagName}${strAttr}>`,
          end: `</${tagName}>`,
        };
      },
    },
  });

  editor.BlockManager.add('video', {
    label: 'Video',
    content: `<${compId} src="http://placehold.it/350x250/78c5d6/fff" />`,
    attributes: {class: 'fa fa-video-camera'},
  });
};
