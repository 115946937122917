import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FieldsList from 'components/channel/field/FieldsList';
import FieldForm from 'components/channel/field/FieldForm';
import Layout from 'components/misc/Layout';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const Fields = ({groupId}) => {
  const classes = useStyles();
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldsList groupId={groupId} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <FieldForm groupId={groupId} />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

Fields.propTypes = {
  groupId: PropTypes.string,
};

export default Fields;
