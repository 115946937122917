import React from 'react';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  btn: {
    maxWidth: '200px',
    height: '70px',
  },
  icon: {
    marginLeft: '10px',
  },
}));

export default function LoginView() {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          localStorage.setItem('restorePath', window.location.pathname);
          window.location =
            process.env.REACT_APP_API_BASE_URL + '/auth/google/start';
        }}
        className={classes.btn}
      >
        Google Login
        <LockOutlinedIcon className={classes.icon} />
      </Button>
    </div>
  );
}
