import React from 'react';
import {render} from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import {history} from 'services';
import {init as initSentry} from '@sentry/browser';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

import store from 'store';
import Root from 'components/Root';
import rootSaga from './sagas';
import theme from './theme';

if (process.env.REACT_APP_SENTRY_DSN) {
  initSentry({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

store.runSaga(rootSaga);

render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Root store={store} history={history} />
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
