import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Switch as SwitchFormik,
  TextField,
  fieldToTextField,
} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';
import {cloneDeep} from 'lodash';
import Title from 'components/misc/Title';
import useStyles from 'components/styles';
import {api, history} from 'services';

const schema = yup.object().shape({
  projectId: yup.string().required(),
  name: yup.string().required(),
  list: yup.string(),
  group: yup.object().required().shape({
    publicCount: yup.bool().required(),
    handicap: yup.number().required(),
    captchaLevel: yup.number().required(),
  }),
  automail: yup.object().shape({
    headerFrom: yup.string(),
    headerSubject: yup.string(),
    replyTo: yup.string(),
    bodyHtml: yup.string(),
  }),
});

// Without channel this is a channel creation form, with channel this is a
// channel edit form.
const ChannelForm = ({projectId, channelId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const channel = useSelector(state => state.channels.items[channelId]);
  const group = useSelector(state =>
    channel ? state.groups.items[channel.group] : null
  );
  const projects = useSelector(state => state.projects.items);
  const isLoading = useSelector(state =>
    Boolean((channelId && (!channel || !group)) || state.channels.isLoading)
  );

  const [mails, setMails] = useState([]);
  const [hasAuto, setHasAuto] = useState(false);

  useEffect(() => {
    if (isLoading || !channel) {
      return;
    }

    if (channel.projectId) {
      updateMails(channel.projectId);
    }

    if (channel.automail && channel.automail.id) {
      setHasAuto(true);
    }
  }, [isLoading, channel]);

  if (isLoading) {
    return <CircularProgress />;
  }

  // Fetch mail lists for project and set widget.
  async function updateMails(projectId) {
    setMails([]);
    // TODO: display spinner?
    let {response, error} = await api.getMailLists({projectId});
    if (error) {
      // TODO: handle errors? project without mailer !== request failed
      // alert(error);
      return;
    }
    response.lists.unshift({ID: '', Name: 'nenhuma lista'});
    setMails(response.lists);
    // TODO: add button to force list reload?
  }

  // This object is needed to overwrite onChange
  const ProjectTextField = props => (
    <MuiTextField
      {...fieldToTextField(props)}
      onChange={async event => {
        const {value} = event.target;
        props.form.setFieldValue(props.field.name, value);
        updateMails(value);
      }}
    />
  );
  ProjectTextField.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
  };

  const ROTextField = props => (
    <MuiTextField
      {...fieldToTextField(props)}
      InputProps={{
        readOnly: true,
      }}
      variant="filled"
    />
  );
  ROTextField.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
  };

  let title = 'Novo Formulário';
  let submitLabel = 'Criar';
  let submitFn = api.createChannel;
  let postSubmitFn;
  let emptyAuto = {
    headerFrom: '',
    headerSubject: '',
    replyTo: '',
    bodyHtml: '',
  };
  let initialValues = {
    projectId: projectId || '',
    name: '',
    list: '',
    group: {
      handicap: 0,
      captchaLevel: 0,
      publicCount: false,
    },
    automail: emptyAuto,
  };

  // Handle sole project case.
  if (initialValues.projectId === '' && Object.keys(projects).length === 1) {
    initialValues.projectId = Object.keys(projects)[0];
  }

  if (channel) {
    title = 'Editar Formulário';
    submitLabel = 'Salvar';
    submitFn = async c => {
      return await api.editChannel(
        Object.assign(c, {channelId: channel.id, groupId: group.id})
      );
    };
    postSubmitFn = data => {
      history.navigate(`/projects/${data.projectId}/channels`);
    };
    initialValues = cloneDeep(channel);
    initialValues.group = cloneDeep(group);
    if (!initialValues.automail) {
      initialValues.automail = emptyAuto;
    }
  }
  return (
    <>
      <Title>{title}</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async values => {
          var data = cloneDeep(values);
          delete data.group.fields;
          if (!hasAuto) {
            delete data.automail;
          }
          await submitFn(data);
          dispatch({type: 'channelsOutdated'});
          if (postSubmitFn) {
            postSubmitFn(data);
          }
        }}
      >
        {({isSubmitting}) => (
          <Form className={classes.root}>
            <Field
              className={classes.field}
              name="name"
              component={TextField}
              label="Nome"
            />

            {channel && Object.keys(projects).length > 1 ? (
              <Field
                className={classes.field}
                name="projectId"
                component={ProjectTextField}
                label="Projeto"
                select
              >
                {Object.values(projects).map(project => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Field>
            ) : null}

            <Field
              className={classes.field}
              name="list"
              component={TextField}
              label="Lista"
              disabled={mails.length === 0}
              select
            >
              {mails.length === 0 ? (
                <MenuItem value={initialValues.list} key={initialValues.list}>
                  carregando...
                </MenuItem>
              ) : (
                Object.values(mails).map(mail => (
                  <MenuItem value={mail.ID} key={mail.ID}>
                    {mail.Name}
                  </MenuItem>
                ))
              )}
            </Field>

            <Field
              className={classes.field}
              name="group.handicap"
              type="number"
              component={TextField}
              label="Handicap"
            />

            <Field
              className={classes.field}
              name="group.captchaLevel"
              type="number"
              component={TextField}
              label="Captcha Level"
              inputProps={{
                step: 0.001,
                min: 0,
                max: 1,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />

            <FormControlLabel
              control={
                <Field name="group.publicCount" component={SwitchFormik} />
              }
              label="Contagem Pública?"
            />

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {submitLabel}
            </Button>

            {channel ? (
              <>
                <Field
                  className={classes.field}
                  name="id"
                  component={ROTextField}
                  label="Channel ID"
                />

                <Field
                  className={classes.field}
                  name="group.id"
                  component={ROTextField}
                  label="Group ID"
                />

                <Field
                  className={classes.field}
                  name="projectId"
                  component={ROTextField}
                  label="Project ID"
                />
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
};

ChannelForm.propTypes = {
  projectId: PropTypes.string,
  channelId: PropTypes.string,
};

export default ChannelForm;
