import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TextField} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';
import {cloneDeep} from 'lodash';

import Title from 'components/misc/Title';
import TemplateEditor from 'components/template/TemplateEditor';
import useStyles from 'components/styles';
import {api, history} from 'services';
import Layout from 'components/misc/Layout';

const schema = yup.object().shape({
  projectId: yup.string().required(),
  name: yup.string().required(),
});

// Without template this is a template creation form, with template this is a
// template edit form.
const TemplateForm = ({templateId, projectId}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const projects = useSelector(state => state.projects.items);
  const template = useSelector(state => state.templates.items[templateId]); // Used when is a template edit form
  const isLoading = useSelector(state =>
    Boolean((templateId && !template) || state.templates.isLoading)
  );

  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (isLoading || !template) {
      return;
    }
  }, [isLoading, template]);

  if (isLoading) {
    return <CircularProgress />;
  }

  let title = 'Novo Template';
  let submitLabel = 'Criar';
  let submitFn = api.createTemplate;
  let postSubmitFn = response => history.navigate(`${response.ID}/edit`);
  let initialValues = {
    projectId: projectId || '',
    name: '',
  };

  // Handle sole project case.
  if (initialValues.projectId === '' && Object.keys(projects).length === 1) {
    initialValues.projectId = Object.keys(projects)[0];
  }

  if (template) {
    title = 'Editar Template';
    submitLabel = 'Salvar';
    submitFn = async c => {
      return await api.editTemplate(
        Object.assign(c, {templateId: template.id})
      );
    };
    postSubmitFn = null; // () => history.navigate(`/templates`);
    initialValues = cloneDeep(template);
  }

  function reloadTemplate() {
    dispatch({type: 'templatesOutdated'});
  }

  return (
    <Layout>
      <Helmet>
        {/* TODO: Remove script tag after this is fixed https://github.com/artf/grapesjs-mjml/issues/155 */}
        <script src="https://unpkg.com/grapesjs-mjml@0.6.0" />
      </Helmet>
      <Paper className={classes.paper}>
        <Title>{title}</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async ({editing, ...values}) => {
            if (showEditor) return;

            var data = cloneDeep(values);
            data = {...data, ...(data.body || {})};
            let {response} = await submitFn(data);
            if (!editing) reloadTemplate();
            if (postSubmitFn) postSubmitFn(response);
          }}
        >
          {({isSubmitting, handleSubmit, setFieldValue}) => (
            <Form className={classes.root}>
              <Field
                className={classes.field}
                name="name"
                component={TextField}
                label="Nome"
              />

              {template && Object.keys(projects).length > 1 ? (
                <Field
                  className={classes.field}
                  name="projectId"
                  label="Projeto"
                  component={TextField}
                  select
                >
                  {Object.values(projects).map(project => (
                    <MenuItem value={project.id} key={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Field>
              ) : null}

              {template ? (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditor(true)}
                >
                  Editar Template
                </Button>
              ) : null}

              {showEditor ? (
                <TemplateEditor
                  editorData={template.body.editor || {}}
                  setShowEditor={setShowEditor}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  reloadTemplate={reloadTemplate}
                />
              ) : null}

              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {submitLabel}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
};

TemplateForm.propTypes = {
  projectsId: PropTypes.string,
  templateId: PropTypes.string,
};

export default TemplateForm;
