import {makeStyles} from '@material-ui/core/styles';

export const red = 'rgb(200, 16, 16)';
export const yellow = 'rgb(198, 160, 11)';
export const green = '#258025';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
  },
  field: {
    marginBottom: theme.spacing(2),
    minWidth: 400,
  },
  textArea: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));
