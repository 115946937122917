import {normalize, schema} from 'normalizr';
import request from './request';

const field = new schema.Entity('fields');
const project = new schema.Entity('projects');
const template = new schema.Entity('templates');
const campaign = new schema.Entity('campaigns');
const pipeline = new schema.Entity('pipelines');
const group = new schema.Entity('groups', {
  fields: [field],
});
const channel = new schema.Entity('channels', {
  group,
});
const AllSchema = {
  channels: [channel],
  projects: [project],
  templates: [template],
  campaigns: [campaign],
  pipelines: [pipeline],
};

export default {
  async renewToken() {
    return await request('auth/token');
  },
  async deleteCampaign(id) {
    return await request(`campaigns/${id}`, 'DELETE');
  },
  async createCampaign(params) {
    return await request('campaigns', 'POST', params);
  },
  async editCampaign({campaignId, ...params}) {
    return await request(`campaigns/${campaignId}`, 'PUT', params);
  },
  async recipientsCampaign(id) {
    return await request(`campaigns/${id}/recipients`, 'GET');
  },
  async startCampaign(id) {
    return await request(`campaigns/${id}/start`, 'POST');
  },
  async stopCampaign(id) {
    return await request(`campaigns/${id}/stop`, 'POST');
  },
  async deleteChannel(id) {
    return await request(`channels/${id}`, 'DELETE');
  },
  // params: projectId, name, channelList, publicCount
  async createChannel(params) {
    return await request('channels', 'POST', params);
  },
  // params: channelId, projectId, name, channelList, publicCount
  async editChannel({channelId, ...params}) {
    return await request(`channels/${channelId}`, 'PUT', params);
  },
  // params: id
  async duplicateChannel({id}) {
    return await request(`channels/${id}/duplicate`, 'POST');
  },
  // get all data accessible by an user
  async getAll() {
    let data = await request('all');
    return {
      response: normalize(data.response, AllSchema).entities,
    };
  },
  // params: name, mailer, domains
  async createProject(params) {
    return await request(`projects`, 'POST', params);
  },
  // params: projectId, name, mailer, domains
  async editProject({projectId, ...params}) {
    return await request(`projects/${projectId}`, 'PUT', params);
  },
  // params: groupId, name, label, type, validation, public
  async createField({groupId, ...params}) {
    return await request(`groups/${groupId}/fields`, 'POST', params);
  },
  // params: fieldId, groupId, name, label, type, validation, public
  async editField({groupId, fieldId, ...params}) {
    return await request(`groups/${groupId}/fields/${fieldId}`, 'PUT', params);
  },
  async deleteField({groupId, fieldId}) {
    return await request(`groups/${groupId}/fields/${fieldId}`, 'DELETE');
  },
  async reorderFields({groupId, ...params}) {
    return await request(`groups/${groupId}/fields_order`, 'PUT', params);
  },
  async getConstants() {
    return await request('constants');
  },
  async getMailLists({projectId}) {
    return await request(`projects/${projectId}/mailer/lists`);
  },
  async aggregate(params) {
    return await request(
      'aggregations/data?' + new URLSearchParams(params).toString()
    );
  },
  // params: projectId, name, html, text, editor
  async createTemplate(params) {
    return await request('templates', 'POST', params);
  },
  // params: templateId, projectId, name, html, text, editor
  async editTemplate({templateId, ...params}) {
    return await request(`templates/${templateId}`, 'PUT', params);
  },
  async duplicateTemplate(id) {
    return await request(`templates/${id}/duplicate`, 'POST');
  },
  async deleteTemplate(id) {
    return await request(`templates/${id}`, 'DELETE');
  },
  // params: projectId, page, pageSize, search, sortFields, sortDirs
  async mailSubscribers({projectId, ...params}) {
    return await request(
      `projects/${projectId}/mail_subscribers?` +
        new URLSearchParams(params).toString()
    );
  },
  async mailSubscriber({projectId, mailSubscriberId}) {
    return await request(
      `projects/${projectId}/mail_subscribers/${mailSubscriberId}`
    );
  },
  thumbnailPrevURL(url) {
    return process.env.REACT_APP_API_BASE_URL + '/thumbnails?url=' + url;
  },
  templatePreview(id) {
    return process.env.REACT_APP_API_BASE_URL + `/campaigns/${id}/template`;
  },
  async mailCampaignEvts({campaignId, event}) {
    return await request(`mail_campaigns/${campaignId}/${event}`);
  },
  // params: name, channelId, tree
  async createPipeline(params) {
    return await request('pipelines', 'POST', params);
  },
  // params: id, name, channelId, tree
  async editPipeline(params) {
    return await request(`pipelines/${params.id}`, 'PUT', params);
  },
  async deletePipeline(id) {
    return await request(`pipelines/${id}`, 'DELETE');
  },
  async mailEvtPerDate({projectId}) {
    return await request(`projects/${projectId}/mail_events`);
  },
};
